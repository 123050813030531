#AlienUserComponentRoot {
    width: 100%;
    padding: 16px 6px;
    box-sizing: border-box;
    border: 1px solid rgb(91, 185, 61);
    background-color: rgb(118, 236, 79);
    margin-bottom: 26px;
    border-radius: 6px;
    transition: filter 0.3s;
    filter: grayscale(0%);
}

.AlienUserComponentRootLoading {
    filter: grayscale(100%) !important ;
    pointer-events: none !important;
    cursor: initial !important;
}

#AlienUserComponentRoot > p {
    text-align: center;
}

#AlienUserComponentRoot > hr {
    border: none;
    border-top: 1px solid rgb(91, 185, 61);
    margin: 16px 0;
}

#AlienUserComponentRoot > div {
    width: 100%;
}

#AlienUserComponentRoot * {
    font-family: monospace !important;
}

#AlienUserComponentDefaultNote {
    background-color: rgb(238, 255, 232);
    width: 100%;
    border: 1px solid rgb(91, 185, 61);
    border-radius: 6px;
    resize: none;
    height: 200px;
    box-sizing: border-box;
    padding: 6px;
    outline: none !important;
    overflow: auto;
    margin-top: 6px;
}

#AlienUserComponentDefaultNote:focus {
    border: 1px solid rgb(91, 185, 61);
}

#AlienUserComponentRoot > div > button {
    padding: 2px 6px;
    box-sizing: border-box;
    border: 1px solid rgb(91, 185, 61);
    background-color: rgb(238, 255, 232);
    border-radius: 6px;
    cursor: pointer;
    margin-top: 6px;
    outline: none !important;
}

#AlienUserComponentRoot > div > button:active {
    background-color: rgb(199, 255, 177);
}