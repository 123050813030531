@media screen and (max-width: 1200px) {
    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* general */

    .PageContent, .PageContentWidth {
        width: calc(100% - 4%);
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote */

    #QuoteToolbarReferenceNumber {
        font-size: 16px !important;
    }

    #QuoteToolbarInput {
        font-size: 12px !important;
        width: 138px;
    }

    #QuoteStatusIcon {
        font-size: 30px;
    }

    .QuoteToolbarInfoWrap > p:last-of-type:not(.Bold12), #EstimatedDeliveryNoteWrap > p {
        font-size: 14px !important;
    }

    .ToolbarCheckoutButton {
        font-size: 14px !important;
        padding: 0 15px !important;
    }

    #QuoteToolbarActions {
        padding: 0 5px 0 10px;
    }

    #QuoteActionIcon {
        font-size: 20px;
    }

    .QuoteToolbarActionsOpen {
        width: 223px
    }

    .QuoteToolbarActionsOpenRFQ {
        width: 285px
    }

    .QuoteActionWrap > p:last-of-type {
        font-size: 10px !important;
    }

    #QuoteSkeletonBottom {
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 0;
    }

    #QuoteSkeletonBottom > span {
        margin-top: 26px;
        margin-left: 30px;
    }

    #QuoteBottomStart {
        width: unset;
        flex: 1;
        margin-right: 30px;
        flex-direction: column;
        align-items: flex-end;
    }

    #QuoteBottomStart .QuoteBottomSection:first-of-type {
        margin-bottom: 26px;
    }

    #QuoteBottomStart > .QuoteBottomSection, .QuoteBottomSection {
        width: 355px;
        min-width: 355px;
    }

    .QuoteBottomSection:first-of-type {
        margin-right: 0;
    }

    .PriceBreakdownElem h4 {
        font-size: 14px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote part */

    .QuotePart {
        justify-content: space-between;
    }

    .QuotePart > div, .SkeletonThumbnail, .SkeletonInfoWrap, .SkeletonsQuantityPriceWrap {
        flex: unset;
    }

    .SkeletonInfoWrap {
        width: 160px;
    }

    .SkeletonSetButtonWrap {
        flex: 1;
    }

    .SkeletonSetButtonWrap > span:not(:nth-of-type(2)) {
        width: 330px !important;
        height: 90px !important;
    }

    .PartThumbnailWrap {
        flex: unset;
    }

    .SkeletonIndexThumbnail {
        position: relative;
        margin-left: 20px;
    }

    .SkeletonThumbnail {
        margin-left: 0;
    }

    .PartIndex, .QuotePartSkeletonIndex {
        position: absolute;
        margin: 0;
        top: 1px;
        left: 1px;
        z-index: 5;
    }

    .PartInfoWrap, .SkeletonInfoWrap {
        margin-left: 5px;
        padding-right: 5px;

        width: unset !important;
        min-width: unset !important;
        flex: 1 !important;
        box-sizing: border-box;
    }

    .SkeletonsQuantityPriceWrap {
        width: 300px;
        min-width: 300px;
        justify-content: space-between;
    }

    .SkeletonPriceElem {
        min-width: unset;
    }

    .PartInfoWrap > h3 {
        font-size: 13px !important;
    }

    .PartPriceWrap {
        margin-left: 20px;
    }

    .PartPriceWrap > div:not(.PartWarningPriceStatement) {
        min-width: 130px;
    }

    .PartWarningPriceStatement {
        width: 130px;
    }

    .PartPriceWrap > div:last-of-type:not(.PartWarningPriceStatement) {
        width: 100%;
    }

    .QuantityPriceWrap {
        width: unset !important;
        min-width: unset !important;
        justify-content: flex-end;
        flex: 1 !important;
    }

    .QuantityPriceWrapSplit {
        justify-content: space-between;
    }

    .QuotePartSplit .PartInfoWrap, .QuotePartSplit .SkeletonInfoWrap {
        padding-right: unset;
    }

    .PartPriceWrap {
        margin-right: 10px;
    }

    .PartThumbnailWrap {
        margin-left: 10px;
    }

    .QuotePartWarningWrap {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 15px 5px 10px;
    }

    .QuotePartWarning {
        position: relative;
        box-sizing: border-box;
        padding: 5px 5px 5px 32px;
        height: unset;
        width: fit-content;
        max-width: calc(100% - 10px);
    }

    .QuotePartWarningIconWrap {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
    }

    .QuotePartWarning > p > span:not(:last-of-type) {
        margin-left: 0;
    }

    .TechnologyFinishWrap {
        flex: 2 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .TechnologyFinishWrapRoot {
        width: 330px;
    }

    .TechnologyFinishEdit {
        padding: 10px;
    }

    .TechnologyFinishInfo > div > p:first-of-type {
        margin-right: 5px;
    }

    .TechnologyFinishInfo > div > p:nth-of-type(2) {
        width: 172px;
    }

    .PriceBreakdownElemMaxWidth {
        max-width: 170px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quantity input */

    .QuantityInput {
        flex: unset;
    }

    .QuantityInput > .Quantity {
        height: 42px;
        min-height: 42px;
        box-sizing: border-box;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* part viewer */

    #PartViewer > div {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* upload drop zone */

    #UploadPartsWrapFullScreen {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* checkout */

    .CheckoutHalf > div {
        width: 480px;
    }

    .CheckoutOptionTab > p, #UploadPO > p:not(#pOUploadMessage) {
        font-size: 14px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* confirmation */

    #ConfirmationHeaderBottom > div {
        width: 182px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user table */

    .UserTableRowActions {
        padding: 0 5px 0 10px;
    }

    .UserTableOption > p:last-of-type {
        font-size: 10px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* text input & select input */

    .TextInputWrap > p:first-of-type, .SelectInputWrap > p:first-of-type, .SelectCountryWrap > p:first-of-type {
        font-size: 14px !important;
    }

    .TextInputWrap > input, .SelectInputWrap > select, #RequestFormTextAreaWrap textarea {
        font-size: 14px !important;
    }

    .TextInputWrap {
        margin-bottom: 10px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* carousel */

    .ImgCarouselFullscreen > .ImgCarousel {
        height: calc(100% - 10px) !important;
        width: calc(100% - 10px) !important;
    }

    .ImgCarouselFullscreen > .ImgCarousel img {
        object-fit: cover;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* footer */

    #FooterImgLinksSectionWrap {
        grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
        grid-gap: 10px;
    }

    .FooterImgLinksSection {
        height: 187px;
        width: 300px;
        padding: 15px 15px 30px 15px;
    }
}