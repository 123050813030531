#WantToRegisterWrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 51;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    animation: fadeIn 0.5s linear 1;
}

#WantToRegisterModal {
    position: relative;
    width: 360px;
    background-color: var(--tones-white);
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
}

#WantToRegisterModalLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blues-trans);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.WantWantToRegisterModalExpand {
    width: 400px !important;
}

#WantToRegisterModalClose {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    color: var(--blues-main);
}

#WantToRegisterModal > p, #WantToRegisterModal > ul {
    width: 100%;
    margin-bottom: 10px
}

#WantToRegisterModal > p > span {
    color: var(--blues-dark);
    cursor: pointer;
    user-select: none;
}

#WantToRegisterModal > form {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    margin: 10px 0;
}

#WantToRegisterModal > form > div:not(#ReCAPTCHAWrappa) {
    color: var(--warning-main);
    align-self: flex-start;
    /* height: 18px; */
}

#WantToRegisterModal > form > input, #WantToRegisterModal > form > button {
    padding: 0;
    border: none;
    border-radius: 3px;
    outline: none;
}

#WantToRegisterModal > form > button {
    border-radius: 3px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    width: 100%;
    border: none;
    outline: none;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
}

#WantToRegisterModal > form > input {
    height: 40px;
    border: var(--border-main);
    padding: 0 20px;
    width: calc(100% - 42px);
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    font-size: 13px;
    background-color: var(--blues-light);
    margin-bottom: 10px;
}

#WantToRegisterModal > form > input:focus {
    border: var(--border-blue);
}