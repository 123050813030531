#UnderReviewWrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 51;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    animation: fadeIn 0.5s linear 1;
}

#UnderReview {
    position: relative;
    background-color: var(--tones-white);
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
}

#UnderReview:not(.UnderReviewAuthenticator) {
    width: 550px;
}

.UnderReviewAuthenticator {
    width: unset;
}

#UnderReview form {
    width: 100%;
}

#UnderReview > p:first-of-type {
    margin-bottom: 10px;
}

#UnderReviewLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--blues-trans);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.MarginBottom {
    margin-bottom: 20px !important;
}

#UnderReview button {
    border-radius: 3px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    border: none;
    outline: none;
    user-select: none;
    padding: 15px 42px;
    white-space: nowrap;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.2s, filter 0.2s;
    margin-right: 15px;
}

#UnderReview p > span, #UnderReviewNewQuote {
    color: var(--blues-main);
    cursor: pointer;
    transition: filter 0.2s;
    user-select: none;
    text-decoration: none;
}

.CopyDisabled {
    cursor: initial !important;
    filter: grayscale(100%);
}

#UnderReviewButtonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #UnderReview p > span:hover, #UnderReview button:not(.CopyDisabled):hover, #UnderReviewNewQuote:hover {
        filter: var(--filter-brightness);
    }
}