html, body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
    display: none;
}

body {
    overflow-x: hidden;
    background-color: #000000;
    min-width: 320px;
}

* {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes dots1 {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    40% {
      color: var(--tones-black);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    60% {
      text-shadow:
        .25em 0 0 var(--tones-black),
        .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
      text-shadow:
        .25em 0 0 var(--tones-black),
        .5em 0 0 var(--tones-black);
    }
}

@keyframes dots2 {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    40% {
      color: var(--tones-dark-grey);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    60% {
      text-shadow:
        .25em 0 0 var(--tones-dark-grey),
        .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
      text-shadow:
        .25em 0 0 var(--tones-dark-grey),
        .5em 0 0 var(--tones-dark-grey);
    }
}

@keyframes priceSheen {
    0% {
        transform: translateX(calc(0% - 50px));
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        transform: translateX(calc(100% + 200px));
        opacity: 0;
    }
}

@keyframes sheen {
    0% {
        transform: translateX(calc(0% - 30px));
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        transform: translateX(calc(100% + 30px));
        opacity: 0;
    }
}

@keyframes glow {
    0% {filter: drop-shadow(0 0 0.2em rgba(255, 215, 0, 0.2));}
    50% {filter: drop-shadow(0 0 0.2em rgba(255, 225, 0, 0.9));}
    100% {filter: drop-shadow(0 0 0.2em rgba(255, 215, 0, 0.2));}
}

@keyframes driveIn {
    0% {transform: translateX(-70px)}
    20% {transform: translateX(-70px)}
    100% {transform: translateX(0px)}
}

@keyframes warningBackground {
    0% {background-color: rgba(255, 0, 0, 0.3)}
    50% {background-color: rgba(255, 0, 0, 0.5)}
    100% {background-color: rgba(255, 0, 0, 0.3)}
}

.Button {
    background-color: white;
    border: none;
    border-radius: 3px;
    padding: 10px 15px;
    cursor: pointer;
}

.Reg10 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
}

.Reg12 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.Reg14 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.Reg14Important {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
}

.Reg16 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.Reg16Important {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.Reg20 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.Bold10 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
}

.Bold12 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}

.Bold12Important {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 15px !important;
}

.Bold14 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.Bold14Important {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
}

.Bold16 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.Bold20 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.Bold30 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
}

.TonesBlack {
    color: var(--tones-black);
}

.TonesBlackImportant {
    color: var(--tones-black) !important;
}

.MainBlue {
    color: var(--blues-main);
}

.EndAlign {
    text-align: end;
}

.NoPointer {
    pointer-events: none !important;
}

.DefaultCursor {
    cursor: default !important;
}

.HideScrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.HideScrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.Page {
    box-sizing: border-box;
    width: inherit;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.PageContentWrap {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: max-content;
    flex: 1;
}

.PageContentWidth {
    max-width: 1200px;
    width: 100%;
}

.PageContent {
    box-sizing: border-box;
    max-width: 1200px;
    width: 100%;
}

.ModalSpanScreen {
    height: calc(var(--vh, 1vh) * 100);
    width: inherit;
    bottom: unset;
    right: unset;
    top: 0;
    left: 0;
}

.FullHeightWidth {
    height: calc(var(--vh, 1vh) * 100) !important;
    width: 100% !important;
}

.Flex0 {
    flex: 0 1 auto;
}