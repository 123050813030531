#UserButton {
    min-width: 220px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

#StartNewQuoteButton {
    border-radius: 3px;
    background-color: var(--tones-white);
    color: var(--tones-black);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    top: -20px;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
    user-select: none;
    margin-right: 10px;
    text-decoration: none;
    height: 30px;
    width: 170px;
}

#UnOpenIconButton {
    padding: 0;
    margin: 2px 2px 0 0;
}

#OpenIconButton {
    padding: 0;
    position: absolute;
    top: 2px;
    right: 2px;
}

#UnOpenAccountIcon {
    font-size: 30px;
    color: var(--tones-white);
}

#OpenAccountIcon {
    font-size: 30px;
    color: var(--blues-dark);
}

#UserPopper {
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 220px;
    background-color: var(--tones-white);
    border-radius: 3px;
    overflow: hidden;
}

.FixedHeightPopper {
    height: 170px;
}

#LogInRegisterWrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#LogInRegisterWrap > div {
    width: calc(100% - 4px);
    height: calc(50% - 2px);
    background-color: var(--tones-white);
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}


#LogInRegisterWrap > div:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    height: calc(50% - 3px);
    border-bottom: var(--border-main);
}

#LogInRegisterWrap > div:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

#UserModal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 51;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    animation: fadeIn 0.5s linear 1;
}

#UserInfoModalClose {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
    color: var(--tones-black);
}

#UserModalContent {
    box-sizing: border-box;
    position: relative;
    width: 550px;
    background-color: var(--tones-white);
    border-radius: 5px;
    box-shadow: var(--box-shadow-main);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
}

#UserModalContentLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blues-trans);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

#UserModalContent > p {
    width: 100%;
    color: var(--tones-dark-grey);
    margin-bottom: 10px;
    box-sizing: border-box;
    padding-right: 20px;
}

#UserModalContent > form {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-top: 10px;
}

#UserModalContent > form > input, #UserModalContent > form > button {
    padding: 0;
    border: none;
    border-radius: 3px;
    outline: none;
}

#UserModalContent > form > input {
    height: 40px;
    border: var(--border-main);
    padding: 0 20px;
    width: calc(100% - 42px);
    background-color: var(--blues-light);
    margin-bottom: 10px;
    transition: border 0.3s;
}

#UserModalContent > form > input:focus {
    border: var(--border-blue);
}

.UserModalWarning {
    border: var(--border-warning) !important;
}

.UserModalFormWarning {
    color: var(--warning-main);
    align-self: flex-start;
    margin-bottom: 5px;
}

.UserModalActiveMessageUL {
    margin: 5px 0;
}

.UserModalMessageInActive, .UserModalMessageActive {
    display: block;
    width: 160px;
    position: relative;
}

.UserModalMessageInActive {
    font-weight: 301;
}

.UserModalMessageInActive::after {
    position: absolute;
    right: 0;
    content: '☑';
    margin-left: 20px;
    text-decoration: unset !important;
}

.UserModalMessageActive {
    font-weight: 401;
}

.UserModalMessageActive::after {
    position: absolute;
    right: 0;
    content: '☐';
    margin-left: 20px;
    text-decoration: unset !important;
}

#UserModalContent > form > button {
    margin-top: 5px;
    border-radius: 3px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    width: 100%;
    border: none;
    outline: none;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.UserModalFormButtonDisabled {
    pointer-events: none;
    background-color: var(--tones-grey) !important;
}

#LoggedInWrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#UserName {
    width: calc(100% - 4px);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    height: 80px;
    background-color: var(--tones-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none !important;
    border-bottom: var(--border-main);
}

#UserName > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 80%;
    color: var(--blues-main);
    user-select: none;
    overflow: hidden;
}

#LoggedInMenu {
    width: calc(100% - 4px);
    min-height: 60px;
    background-color: var(--tones-white);
}

.LoggedInMenuItem {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s;
}

.LoggedInMenuItem > p {
    margin-left: 10px;
    user-select: none;
    transition: color 0.3s;
}

#LoggedInMenu > hr {
    border: none;
    border-top: var(--border-main);
}

.LoggedInMenuItemIcon {
    margin-right: 10px;
    font-size: 18px !important;
    color: var(--tones-grey);
    user-select: none;
    transition: color 0.3s;
}

.LoggedInMenuItemIconActive {
    color: var(--blues-dark);
}

.LoggedInMenuItem:last-of-type {
    margin-bottom: 2px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

#ExtraBits {
    width: 100%;
    margin-top: 10px;
}

.ForgotSomething {
    display: inline-block;
    margin-right: 15px;
    color: var(--blues-dark);
    cursor: pointer;
    user-select: none;
}

.ForgotSomething:last-of-type {
    margin-right: 0;
}

#ReCAPTCHAWrappa {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px !important;
}

#UserNavBar {
    display: flex;
    align-items: center;
}

#UserNavBar > div {
    height: 30px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#UserNavBar > div > p {
    color: var(--tones-white);
    white-space: nowrap;
    cursor: pointer;
    padding: 5px;
    user-select: none;
}


#UserNavSpacer {
    height: 30px;
    width: 30px;
    margin: 2px 2px 0 0;
}

.AuthenticatorActiveMessage {
    cursor: pointer;
    font-weight: 401;
    color: var(--blues-dark);
}


/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hamburger */

#UserMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.UserMenuItem {
    cursor: pointer;
    color: var(--tones-white);
    height: 40px !important;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: border-box;
    user-select: none;
    text-decoration: none;
}

.UserMenuItem:last-child {
    margin-bottom: 0;
}

.HamburgerIconButton {
    position: absolute !important;
    top: 3px !important;
    right: -1px !important;
}

.HamburgerAccountIcon {
    color: var(--tones-white) !important;
}

.CurrencySelectorRoot {
    background-color: unset !important;
    border: none !important;
    margin-right: 10px;
}

.CurrencySelectorRootHamburger {
    background-color: unset !important;
    border: none !important;
    margin-right: 0;
}

.CurrencySelectorSelect {
    color: var(--tones-white) !important;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    padding: unset !important;
    width: 72px !important;
}

.CurrencySelectorSelect[aria-expanded="true"] {
    background-color: rgba(0, 0, 0, 0) !important;
}

.CurrencySelectorSelect:focus {
    background-color: rgba(0, 0, 0, 0) !important;
}

.CurrencySelectorIcon {
    color: var(--tones-white) !important;
    font-size: 18px !important;
    position: relative;
    right: 5px !important;
    top: 0 !important;
}

#AuthenticatorConsentWrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 5px;
}

#AuthenticatorConsentWrap > p {
    color: var(--tones-dark-grey);
}

#AuthenticatorConsentRequiredWarning {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    box-sizing: border-box;
}

.AuthenticatorConsentRequiredWarningActive {
    border-bottom: 2px solid var(--warning-main) !important;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media(hover: hover) {
    #UserNavBar > div > p:hover {
        transform: translateX(1px) translateY(-1px);
    }

    #StartNewQuoteButton:hover {
        background-color: var(--blues-dark);
        color: var(--tones-white);
    }

    #UnOpenAccountIcon:hover {
        color: var(--blues-light);
    }

    #OpenAccountIcon:hover {
        filter: var(--filter-brightness);
    }

    #LogInRegisterWrap > div:hover {
        background-color: var(--blues-light);
        color: var(--blues-dark);
    }

    #UserInfoModalClose:hover {
        color: var(--blues-main);
    }

    #UserModalContent > form > button:hover {
        background-color: var(--blues-main);
    }

    .LoggedInMenuItem:hover {
        background-color: var(--blues-light);
    }

    .LoggedInMenuItem:hover > p {
        color: var(--blues-dark);
    }

    .LoggedInMenuItem:hover > .LoggedInMenuItemIcon {
        color: var(--blues-dark);
        transform: translateX(-3px);
    }

    #UserNavBar > div > p:hover {
        transform: translateX(1px) translateY(-1px);
    }

    .AuthenticatorActiveMessage:hover {
        filter: var(--filter-brightness);
    }

    .UserMenuItem:hover {
        padding-left: 2px;
        filter: var(--filter-brightness);
    }

    .AuthenticatorActiveMessage:hover {
        filter: var(--filter-brightness);
    }
}