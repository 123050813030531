#DropDownList {
    position: absolute;
    z-index: 12;
    width: 100%;
    border: var(--border-main);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    box-shadow: var(--box-shadow-bottom);
}

.DropDownOption {
    height: 100%;
    border-bottom: var(--border-main);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--tones-white);
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.2s;
}

.DropDownOption:last-of-type {
    border-bottom: none;
}

.DropDownOptionDisabled {
    cursor: default;
    filter: saturate(0%);
    background-color: var(--tones-light-grey);
}

.DropDownOptionLoading {
    background-color: var(--blues-light) !important;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .DropDownOption:not(.DropDownOptionDisabled):hover {
        background-color: var(--blues-light);
    }

    .DropDownOption:not(.DropDownOptionDisabled):hover *[highlightonhover='true'] {
        color: var(--blues-main)
    }
}