#QuotePartOverlay {
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: opacity 0.2s;
    box-sizing: border-box;
}

#QuotePartOverlayTop {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--tones-black-trans);
}

#QuotePartOverlayRight {
    position: absolute;
    height: initial;
    background-color: var(--tones-black-trans);
}

#QuotePartOverlayBottom {
    position: absolute;
    background-color: var(--tones-black-trans);
}

#QuotePartOverlayLeft {
    position: absolute;
    left: 0;
    background-color: var(--tones-black-trans);
}