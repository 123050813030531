.MoreInfoI {
    transform: rotate(180deg);
    font-size: 15px !important;
    color: var(--tones-dark-grey);
    cursor: pointer;
    transition: color 0.2s;
}

.MoreInfoISmaller {
    font-size: 13px !important;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .MoreInfoI:hover {
        color: var(--blues-main);
    }
}