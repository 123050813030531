#RequestQuoteWrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 51;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    animation: fadeIn 0.5s linear 1;
}

#RequestQuote {
    position: relative;
    background-color: var(--tones-white);
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
}

#RequestQuote > p:first-of-type {
    margin-bottom: 10px;
}

#RequestQuote:not(.RequestQuoteAuthenticator) {
    min-width: 400px;
}

.RequestQuoteAuthenticator {
    width: unset;
    min-width: unset;
}

#RequestQuote form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
}

#RequestQuoteClose {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    color: var(--tones-dark-grey);
}

.RequestQuoteDoubleInputWrap {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

#RequestFormTextAreaWrap {
    min-width: 100%;
    min-height: 200px;
    flex: 1;
}

#RequestFormTextAreaWrap textarea {
    border: var(--border-main);
    border-radius: 3px;
    min-width: 100%;
    min-height: 200px;
    height: 100%;
    width: 100%;
    max-width: 700px;
    max-height: 370px;
    color: var(--tones-dark-grey);
    box-sizing: border-box;
    outline: none;
    padding: 10px;
}

.ReducedHeight {
    min-height: 120px !important;
    max-height: 150px !important;
}

#RequestQuoteSubmit, #RequestQuote button {
    border-radius: 3px;
    background-color: var(--warning-sick-yellow);
    color: var(--tones-black);
    border: none;
    outline: none;
    user-select: none;
    padding: 15px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    transition: background-color 0.2s, filter 0.2s;
}

.SubmitDisabled {
    pointer-events: none;
    background-color: var(--tones-dark-grey) !important;
}

#RequestQuoteUserInfo {
    width: 100%;
    margin-bottom: 20px;
}

#RequestQuoteUserInfo > p:nth-child(even) {
    color: var(--tones-dark-grey);
}

#RequestQuoteUserInfo > p:nth-child(even):not(:last-of-type) {
    margin-bottom: 10px;
}

#RequestQuoteCreateAccount {
    margin-bottom: 10px;
    user-select: none;
}

#RequestQuoteCreateAccount span {
    cursor: pointer;
    color: var(--blues-main);
    transition: filter 0.2s;
}

#RequestQuoteLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--blues-trans);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

#RequestQuote button:not(#RequestQuoteSubmit) {
    background-color: var(--blues-main);
    color: var(--tones-white);
}

.RFQMarginBottom {
    margin-bottom: 20px !important;
}

#RequestQuoteChangeDetails {
    text-decoration: none;
    color: var(--blues-main);
}

#RFQAttachmentsWrap {
    margin-top: 8px;
    width: 100%;
}

#RFQAttachButtonWrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

#RFQAttachButtonWrap > p:last-of-type {
    transition: color 0.3s;
    color: var(--tones-black);
}

.attachLimitWarning {
    color: var(--warning-main) !important;
}

#RFQAttachFilesButton {
    color: var(--blues-main);
    cursor: pointer;
    transition: filter 0.3s;
    user-select: none;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

#AttachmentUploadIcon {
    font-size: 15px !important;
    margin-right: 3px;
}

#RFQAttachmentsList {
    box-sizing: border-box;
    width: 100%;
    border: var(--border-main);
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 8px;
    max-height: 70px;
    overflow-y: auto;
}

.Attachment {
    background-color: var(--tones-white);
    padding: 3px 7px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s !important;
}

.AttachmentLoading {
    pointer-events: none;
    background-color: var(--warning-light-sick-yellow);
}

.Attachment > p:first-of-type {
    flex: 1;
    margin: 0;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AttachmentLoadingDots {
    padding-right: 7px;
    animation: dots1 1s steps(5, end) infinite;
}

.AttachmentLoadingDots::before {
    content: '.';
}

.DeleteAttachment {
    font-size: 13px !important;
    color: var(--tones-black);
    transition: color 0.3s !important;
}

.AttachmentFailed {
    background-color: var(--warning-light) !important;
}

.AttachmentFailedMessage {
    color: var(--warning-main);
}

.RFQAttachFilesButtonDisabled {
    pointer-events: none;
    color: var(--tones-dark-grey);
}

.RequestQuoteSubmitDisabled {
    pointer-events: none;
    filter: grayscale(100%);
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #RequestQuote button:hover, #RequestQuoteCreateAccount span:hover, #RFQAttachFilesButton:hover {
        filter: var(--filter-brightness);
    }

    #RequestQuoteClose:hover {
        color: var(--blues-main);
    }

    .Attachment:hover {
        background-color: var(--blues-light);
    }

    .Attachment:hover > .DeleteAttachment {
        color: var(--blues-main);
    }
}