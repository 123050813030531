#UploadDropZoneFullScreen {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    z-index: 99;
}

#UploadDropZoneChild {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#UploadInfoWrap {
    text-align: center;
}

#UploadPartsWrapFullScreen {
    background-color: var(--tones-white);
    border: var(--border-main);
    box-sizing: border-box;
    height: calc(100% - 20px);
    position: relative;
    width: calc(100% - 20px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    transition: border 0.3s;
    box-shadow: var(--box-shadow-main);
    max-height: calc(675px + 3%);
    max-width: calc(1200px + 3%);
}

#UploadPartsWrapChild {
    width: 100%;
    background-color: var(--tones-white);
    min-height: 450px;
    border: var(--border-main);
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    transition: border 0.3s;
}

#UploadPartsHeader {
    width: 100%;
    border-bottom: var(--border-main);
    box-sizing: border-box;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#UploadingPartsHeaderStartWrap {
    margin-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#UploadingPartsHeaderEndWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#UploadingPartsHeaderEndWrap > p {
    margin: 0 20px 0 0;
}

#UploadingPartsHeaderEndWrap > p > span {
    color: var(--warning-main);
}

#UploadInfoWrapFullScreen {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

#UploadInfoWrapFullScreen > #UploadOverview {
    height: calc(100% - 80px);
    box-sizing: border-box;
    overflow-y: scroll;
}

#UploadInfoWrapChild {
    min-height: 100%;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UploadInfoWrapUploading {
    display: initial !important;
}

#UploadInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#UploadButtonLabel, #UploadButtonLabel2 {
    cursor: pointer;
    margin: 20px;
}

#UploadButton {
    background-color: var(--blues-main);
    color: var(--tones-white);
    padding: 12px 20px;
    transition: filter 0.3s;
    pointer-events: none;
}

#UploadInfo > div > p {
    color: var(--tones-dark-grey);
}

#UploadInfo > div > p > span {
    color: var(--tones-black);
}

.DragActive {
    border: var(--border-blue) !important;
}

#UploadOverview {
    height: 100%;
    flex: 1;
    width: 100%;
}

#UploadButtonLabel2 {
    margin: 0;
    cursor: pointer;
    height: 100%;
}

#UploadButton2 {
    background-color: var(--tones-white);
    color: var(--tones-black);
    height: 100%;
    padding: 12px 20px;
    border-radius: 0;
    pointer-events: none;
    border-left: var(--border-main);
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: background-color 0.2s, border 0.2s, color 0.2s, filter 0.3s;
}

#AddMorePartsButton {
    stroke: var(--tones-black);
    margin-right: 10px;
}

#CloseDropZoneButton {
    background-color: var(--tones-white);
    height: 100%;
    padding: 12px 30px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0;
    border-left: var(--border-main);
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: background-color 0.2s, border 0.2s, color 0.2s, filter 0.3s;
}

#CloseDropZoneButtonIcon {
    font-size: 25px;
    color: var(--tones-black);
    transition: color 0.2s;
}

#DropzoneFullscreenContinueWrap {
    width: 100%;
    height: 80px;
    padding: 0 15px;
    border-top: var(--border-main);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#DropzoneFullscreenContinueButton, #DropzoneChildContinueButton, #DropzoneFullscreenInitialContinueButton, #DropzoneFullscreenInitialAddMoreButton {
    background-color: var(--blues-main);
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 15px;
    color:var(--tones-white);
    cursor: pointer;
    transition: background-color 0.4s, border 0.2s, color 0.2s, filter 0.4s;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
}

#DropzoneChildContinueButton {
    box-shadow: none;
    position: absolute;
    bottom: -50px;
    right: 0;
}

.DropzoneFullscreenContinueButtonDisabled {
    background-color: var(--tones-grey) !important;
    cursor: initial !important;
}

#DropzoneFullscreenInitialPromptWrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: var(--blues-light-trans);
    display: flex;
    align-items: center;
    justify-content: center;
}

#DropzoneFullscreenInitialPrompt {
    box-sizing: border-box;
    height: 240px;
    position: relative;
    border-radius: 3px;
    width: 390px;
    box-shadow: var(--box-shadow-main);
    padding: 30px;
    background-color: var(--tones-white);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

#DropzoneFullscreenInitialPrompt > p {
    color: var(--tones-dark-grey)
}

#DropzoneFullscreenInitialAddMoreButtonLabel {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    z-index: 1;
    cursor: pointer;
}

#DropzoneFullscreenInitialContinueButton, #DropzoneFullscreenInitialAddMoreButton {
    width: 100%;
    box-sizing: border-box;
}

#DropzoneFullscreenInitialContinueButton {
    margin-top: 10px;
}

#DropzoneFullscreenInitialAddMoreButton {
    background-color: var(--tones-white);
    border: var(--border-main);
    color: var(--tones-black);
    pointer-events: none;
}

#DropzoneFullscreenInitialCloseButton {
    position: absolute;
    border: none;
    outline: none;
    top: 0;
    right: 0;
    height: 50px;
    z-index: 2;
    width: 50px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 4px;
    background-color: var(--tones-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

#DropzoneFullscreenInitialCloseButtonIcon {
    font-size: 22px;
    color: var(--tones-dark-grey);
    transition: color 0.2s;
}

#DropzoneFullscreenInitialDontShow {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--tones-dark-grey);
    transition: color 0.3s;
    cursor: pointer;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #UploadButtonLabel:hover > button {
        filter: var(--filter-brightness);
    }

    #UploadButton:hover {
        filter: var(--filter-brightness);
    }

    #UploadButtonLabel2:hover > #UploadButton2, #DropzoneFullscreenInitialAddMoreButtonLabel:hover > #DropzoneFullscreenInitialAddMoreButton {
        background-color: var(--blues-light) !important;
    }

    #CloseDropZoneButton:hover {
        background-color: var(--blues-dark);
    }

    #CloseDropZoneButton:hover > #CloseDropZoneButtonIcon {
        color: var(--tones-white);
    }

    #DropzoneFullscreenContinueButton:hover, #DropzoneFullscreenInitialContinueButton:hover {
        filter: var(--filter-brightness);
    }

    .DropzoneFullscreenContinueButtonDisabled:hover {
        filter: brightness(100%) !important;
    }

    #DropzoneFullscreenInitialCloseButton:hover > #DropzoneFullscreenInitialCloseButtonIcon {
        color: var(--tones-black);
    }

    #DropzoneFullscreenInitialDontShow:hover {
        color: var(--tones-black)
    }
}