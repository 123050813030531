#QuoteFalseBlur {
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
}

#QuoteToolbar {
    min-height: 68px;
    width: 100%;
    background-color: var(--tones-white);
    box-shadow: var(--box-shadow-main);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 60px;
    z-index: 13;
}

#QuoteSkeletonToolbarWrap {
    position: sticky;
    top: 60px;
    background-color: var(--tones-white);
    box-shadow: var(--box-shadow-main);
    margin-bottom: 45px;
    width: 100%;
    height: 68px;
    z-index: 13;
}

.QuoteToolbarElem {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

#QuoteToolbarWrap {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start ;
}

.QuoteToolbarInputDisabled {
    cursor: default;
    filter: saturate(0%);
}

#ToolbarInputWarning {
    position: absolute;
    color: var(--warning-main);
    top: 14px;
    left: 24px;
}

#QuoteToolbarRefWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

#QuoteToolBarEndElemMargin {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}

#QuoteToolBarEndElemNoMargin {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.QuoteToolbarElem:first-of-type {
    padding-left: 20px;
}

.QuoteToolbarElem:last-of-type {
    padding-right: 20px;
}

#NicknameEditIcon {
    color: var(--blues-main);
    position: absolute;
    top: -2px;
    right: -18px;
    font-size: 13px;
    pointer-events: none;
}

#QuoteToolbarNicknameWrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#QuoteToolbarNickname {
    cursor: pointer;
    position: relative;
    color: var(--tones-dark-grey);
    display: inline-block;
}

#EditNicknameSpan {
    margin-left: 5px;
}

#QuoteToolbarInput {
    margin-left: 20px;
    height: 65%;
    border: var(--border-main);
    width: 150px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    background-color: var(--blues-light);
    outline: none;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0 20px;
}

#QuoteToolbarInput:focus {
    border: var(--border-blue);
}

#QuoteStatusIcon {
    font-size: 40px;
    color: var(--warning-yellow);
    margin-right: 10px;
}

.QuoteExpiredIcon {
    color: var(--warning-main) !important;
}

#QuoteUnderReviewIcon {
    font-size: 40px;
    color: var(--blues-main);
    margin-left: 20px;
}

.QuoteToolbarInfoWrap > p:first-of-type {
    color: var(--tones-dark-grey);
}

.QuoteToolbarReverseInfoWrap {
    text-align: end;
}

#QuoteToolbarCheckout {
    border-radius: 3px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    width: 100%;
    border: none;
    outline: none;
    padding: 0 25px;
    cursor: pointer;
    margin-left: 20px;
    transition: filter 0.2s;
    height: 65%;
}

#QuoteToolbarActions {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border: var(--border-main);
    padding: 0 20px;
    border-radius: 3px;
    height: 65%;
    user-select: none;
}

#QuoteToolbarActions > p {
    margin-right: 20px;
}

.QuoteToolbarActionsOpen {
    width: 282px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.QuoteToolbarActionsOpenRFQ {
    width: 354px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#QuoteActionIcon {
    color: var(--tones-dark-grey);
    transform: rotate(0deg);
    transition: transform 0.2s;
}

.QuoteActionWrap {
    white-space: break-spaces;
}

.QuoteActionLoading {
    background-color: var(--blues-light);
}

.QuoteActionLoading > p:first-of-type {
    color: var(--blues-main);
}

.QuoteActionLoadingP::after {
    content: ' .';
    animation: dots1 1s steps(5, end) infinite;
}

#QuoteToolbarFloatingIconsWrap {
    bottom: -33px;
    left: 6px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

#QuoteStatusIconFloats {
    pointer-events: all;
    color: var(--warning-yellow);
    font-size: 24px !important;
    margin-right: 3px;
}

.Favourite {
    pointer-events: all;
    color: gold;
    font-size: 24px !important;
    animation: glow 2.4s ease-in-out infinite;
}

#QuoteLeadTimeWrap {
    width: 100%;
    margin-top: 35px;
    border-radius: 3px;
    background-color: var(--tones-white);
    border: var(--border-main);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

#QuoteLeadTimeStartWrap {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;
    flex: 2;
}

#QuoteLeadTimeStartWrap {
    flex: 1;
}

.StepReference {
    color: var(--tones-dark-grey);
    margin-bottom: 3px;
}

#PartsWrap {
    width: 100%;
    min-height: 450px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    transition: border 0.3s;
}

.PartsWrapMinHeight {
    min-height: unset !important;
}

#PartsWrapHeader {
    width: 100%;
    border: var(--border-main);
    background-color: var(--tones-white);
    height: 70px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-sizing: border-box;
}

.PartsWrapHeaderHideBorderBottom {
    border-bottom: var(--border-white) !important;
}

#PartsHeaderStartWrap {
    margin-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#Parts {
    height: 100%;
    flex: 1;
    width: 100%;
}

#QuoteLeadTimeWrap {
    height: 90px;
}

#SelectLeadTimeSelect {
    user-select: none;
    position: relative;
    border-left: var(--border-main);
    width: calc(32% - 1px)   ;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    box-sizing: border-box;
}

.SelectLeadTimeSelectOpen {
    border-bottom-right-radius: 0 !important;
}

.SelectLeadTimeSelectDisabled {
    pointer-events: none !important;
}

.SelectLeadTimeSelectHover {
    background-color: var(--blues-light);
}

#SelectLeadTimeSelectLeft {
    pointer-events: none;
    margin-left: 20px;
}

.LeadTimeOptionWrap {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    color: var(--tones-dark-grey);
}

.LeadTimeOptionWrapSelected {
    padding-left: 15px;
}

.LeadTimeOptionWrap > div:last-of-type, .ShippingRateOptionWrap > div:last-of-type {
    color: var(--tones-dark-grey);
}

.ListTitleLabelLeadTime > span {
    font-weight: bolder;
}

#SelectLeadTimeSelectLeft > p, .LeadTimeOptionP {
    margin-bottom: 3px;
}

.EstimatedShippingTitle {
    position: relative;
    display: inline-block;
    color: var(--tones-black);
}

.ShippingRateOptionWrap {
    height: 50px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--tones-dark-grey);
    box-sizing: border-box;
}

.ShippingRateOptionWrapSelected {
    padding-left: 15px;
}

.ShippingRateNoOption {
    border: var(--border-main);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.ShippingOptionTitle {
    white-space: nowrap;
    color: var(--tones-black);
    margin-bottom: 3px;
}

#SelectLeadTimeSelectLeft > p:first-of-type {
    color: var(--tones-dark-grey);
}

#SelectLeadTimeIcon {
    font-size: 30px;
    pointer-events: none;
    transform: rotate(0deg);
    margin-right: 20px;
    transition: transform 0.2s;
}

.OpenIcon {
    transform: rotate(-180deg) !important;
}

/* BOTTOM */
#QuoteBottomWrap, #QuoteSkeletonBottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 26px;
    margin-bottom: 50px;
}

.QuoteBottomSection {
    width: 32%;
    min-width: 32%;
}

#QuoteBottomStart {
    width: 65.5%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#QuoteBottomStart .QuoteBottomSection {
    width: 48%;
}

#LeadTimeCountDownWrap {
    margin-top: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#LeadTimeCountDownWrap > p {
    color: var(--tones-dark-grey);
}

#LeadTimeCountDownWrap > p > span {
    font-weight: 501;
    text-decoration: underline;
    color: var(--tones-black);
}

#PriceBreakdownWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#PriceBreakdownWrap > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

#PriceBreakdown {
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: var(--tones-white);
    border-left: var(--border-main);
    border-right: var(--border-main);
    box-sizing: border-box;
    border-radius: 3px;
}

.PriceBreakdownElem {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--border-main);
    white-space: nowrap;
    box-sizing: border-box;
    padding: 20px;
}

.PriceBreakdownElemBorderTop {
    border-top: var(--border-main);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.PriceBreakdownElem:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.PriceBreakdownElem > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PriceBreakdownElem h4 {
    color: var(--tones-dark-grey);
}

.PriceBreakdownElem p {
    color: var(--tones-dark-grey);
}

.PriceBreakdownElem:last-of-type p {
    color: var(--blues-main);
}

#PriceBreakdownWrap > div > p {
    color: var(--tones-dark-grey);
    margin-top: 20px;
}

.PriceBreakDownElemColour {
    justify-content: flex-start;
}

.PriceBreakdownColourPreview {
    width: 40px;
    min-width: 40px;
    height: 15px;
    border-radius: 2px;
    box-sizing: border-box;
    margin-right: 7px;
}

.PriceBreakdownElemMaxWidth {
    text-overflow: ellipsis;
    max-width: 190px;
    overflow: hidden;
}

.PriceBreakDownElemColour > p {
    flex: 1;
    text-align: end;
}

.PriceBreakdownSpanMaxWidth {
    max-width: 160px;
    overflow: hidden;
    background-color: pink;
}

.PriceBreakdownSpanMoreInfo {
    margin-left: 3px;
}

#ShowDropZoneButton {
    color: var(--tones-black);
    height: 100%;
    padding: 12px 20px;
    border-radius: 0;
    border-left: var(--border-main);
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: background-color 0.2s, border 0.2s, color 0.2s, filter 0.3s;
}

#ShowDropZoneIcon {
    stroke: var(--tones-black);
    margin-right: 10px;
}

#ShowDropZoneButtonBottom {
    cursor: pointer;
    width: 100%;
    background-color: var(--tones-white);
    border: var(--border-main);
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: background-color 0.3s;
    box-sizing: border-box;
}

.QuoteWarning {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 25px;
    z-index: 1;
    background-color: var(--warning-light);
    padding-right: 5px;
    border-radius: 3px;
    margin-top: 20px;
    min-width: calc(100% - 5px);
}

.QuoteWarning:last-of-type {
    margin-bottom: 25px;
}

.QuoteReqForWarning {
    background-color: var(--warning-light-sick-yellow);
    position: relative;
    height: unset;
    padding: 5px 5px 5px 27px;
    box-sizing: border-box;
}

.QuoteWarningIconWrap {
    border-radius: 3px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning-main);
}

.QuoteBottomWarningIcon {
    color:var(--tones-white);
    font-size: 16px !important;
}

.QuoteReqForWarning > .QuoteWarningIconWrap {
    background-color: var(--warning-sick-yellow) !important;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

.QuoteReqForWarning > .QuoteWarningIconWrap > .QuoteBottomWarningIcon {
    color: var(--tones-black) !important;
}

.QuoteWarning > p {
    border-radius: 3px;
    color: var(--tones-black) !important;
    padding: 5px;
    white-space: nowrap;
    margin-left: 5px;
}

.QuoteReqForWarning > p {
    margin: 0;
    white-space: normal;
}

.QuoteReqForWarning span {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
}

#NoPartsDiv {
    height: 115px;
    border: var(--border-main);
    background-color: var(--tones-white);
    border-radius: 3px;
    width: 100%;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#ExpiredTopHeader {
    height: 100%;
    display: flex;
    align-items: flex-end;
}

#ExpiredQuoteMemo {
    margin-top: 26px;
    margin-bottom: 50px;
    padding: 20px;
    box-sizing: border-box;
    border: var(--border-main);
    border-radius: 3px;
    background-color: var(--tones-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#ExpiredCopyButton {
    outline: none;
    background-color: var(--blues-main);
    transition: filter 0.3s;
    border-radius: 3px;
    padding: 15px 25px;
    box-sizing: border-box;
    color: var(--tones-white);
    border: none;
    white-space: nowrap;
    cursor: pointer;
    min-width: 160px;
    margin-left: 20px;
}

.ExpiredButtonDisabled {
    cursor: default !important;
    filter: saturate(0%);
}

#EstimatedDeliveryNoteWrap {
    margin-top: 20px;
    white-space: nowrap;
}

#EstimatedDeliveryNoteWrap > p {
    color: var(--tones-dark-grey)
}

.CheckoutButton {
    margin-top: 20px;
    border-radius: 3px;
    background-color: var(--blues-dark);
    color: var(--tones-white);
    border: none;
    outline: none;
    width: 100%;
    user-select: none;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.2s, filter 0.2s;
}

.CheckoutButtonRequest {
    background-color: var(--warning-sick-yellow);
    color: var(--tones-black);
}

.CheckoutButtonLoading {
    background-color: var(--tones-dark-grey) !important;
    pointer-events: none;
}

#QuoteHelpRequest {
    text-align: end;
    user-select: none;
    margin-top: 10px !important;
}

#QuoteHelpRequest span {
    color: var(--blues-main);
    cursor: pointer;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #QuoteToolbarNickname:hover #EditNicknameSpan {
        filter: var(--filter-brightness);
    }

    #QuoteToolbarCheckout:hover {
        filter: var(--filter-brightness);
    }

    #ShowDropZoneButton:hover {
        background-color: var(--blues-light);
    }

    #ShowDropZoneButtonBottom:hover {
        background-color: var(--blues-light);
    }

    #QuoteDismissibleNoteModalButtonWrap > button:hover {
        filter: var(--filter-brightness);
    }

    #ExpiredCopyButton:not(.ExpiredButtonDisabled):hover {
        filter: var(--filter-brightness);
    }

    .CheckoutButton:hover, #QuoteHelpRequest span:hover {
        filter: var(--filter-brightness);
    }
}