#Footer {
    position: relative;
    width: 100%;
    background-color: #E8F1FF;
}

#FooterSectionsWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 30px 0;
}

#FooterSections {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

}

.FooterSection {
    height: 100%;
    width: 32%;
    white-space: nowrap;
}

.FooterSection > h3 {
    margin-bottom: 10px;
}

.FooterSection  p {
    margin-bottom: 3px;
}

.FooterSection a {
    text-decoration: none;
    color: inherit;
}

#BottomThemeBar {
    height: 27px;
    width: 100%;
    background: linear-gradient(90deg, #005FFE, #521691);
}