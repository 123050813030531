#PrivacyPolicy {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

#PrivacyPolicyWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    /* white-space: nowrap; */
}

#PrivacyPolicyWrap > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#PrivacyIcon {
    color: var(--blues-dark);
    font-size: 20px;
    margin-right: 10px;
}

#PrivacyPolicyWrap > h4 {
    color: var(--tones-dark-grey);
}

#PrivacyPolicyWrap > h4 > a {
    color: var(--tones-black);
}