#AlienWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: inherit;
    z-index: 9999;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 10px 10px 0;
    pointer-events: none;
}

#AlienAssuming {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    box-shadow: inset 0 0 30px lime;
    pointer-events: none;
    z-index: 10000;
}

#AlienWholeWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 10000;
}

#AlienAssuming > div, #AlienWholeWrap > div {
    position: absolute;
    min-width: 210px;
    border-radius: 23px / 50px;
    box-shadow: 0px 0px 10px lime;
    transition: opacity 0.3s;
}

#AlienAssumingData {
    top: 5px;
    left: 5px;
}

#AlienAssuming > div > div, #AlienWholeWrap > div > div {
    pointer-events: none;
    position: relative;
    background: linear-gradient(120deg, silver, rgb(135, 135, 135));
    /* height: 100%; */
    width: 100%;
    box-sizing: border-box;
    padding: 14px;
    border-radius: 23px / 50px;
    text-shadow: 1px 1px 3px lime;
    border: 1px solid rgb(161, 161, 161);
    box-shadow: inset -1px -2px 3px black;
}

#AlienAssuming > div > div::after, #AlienWholeWrap > div > div::after {
    position: absolute;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    content: '';
    left: 2px;
    top: 2px;
    border-radius: 20px / 50px;
    border: 2px dotted rgba(91, 0, 136, 0.5);
}

#AlienAssuming > div > div > p, #AlienWholeWrap > div > div > p {
    font-family: monospace;
    width: 100%;
    font-weight: bold;
    color: rgb(91, 0, 136) !important;
    text-align: center;
    font-size: 14px;
}

#AlienButtonWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    perspective-origin: center;
    perspective: 250px;
    transition: filter 0.3s;
    filter: invert(0%);
    cursor: pointer;
    margin: 0 20px 0 0;
}

.AlienAssumingHiddenP {
    filter: invert(0%);
    animation: flash 2s infinite ease;
    font-family: monospace !important;
}

/*text flash keyframes*/
@keyframes flash {
    0% { filter: invert(0%); }
    50% { filter: invert(100%) }
    100% { filter: invert(0%); }
}

#AlienButtonWrap > p {
    font-size: 100px;
    animation: admin_alien 60s linear infinite;
    pointer-events: all;
}

#AlienTerminal {
    position: relative;
    min-height: 60px;
    min-width: 350px;
    margin-right: 20px;
    border: 2px solid rgb(0, 16, 59);
    border-radius: 6px;
    box-sizing: border-box;
    background-color: rgba(0, 16, 59, 0.7);
    padding: 10px 25px 10px 10px;
    opacity: 0;
    transition: opacity 0.2s;
}

#AlienTerminal > div {
    position: absolute;
    top: -1px;
    right: 4px;
    color: white;
    font-weight: 401;
    cursor: pointer;
    padding: 6px;
    box-sizing: border-box;
    font-size: 13px;
    z-index: 2;
    user-select: none;
    pointer-events: all;
}

#AlienTerminal:after {
    content: '';
    position: absolute;
    right: 13px;
    bottom: 32px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: rgb(0, 16, 59);
    border-right: 0;
    border-top: 0;
    margin-top: -7px;
    margin-right: -25px;
    border-top-right-radius: 0;
}

@keyframes adminType {
    0% { width: 0%; }
    100% { width: 100% }
}

#AlienTerminal > p {
    position: relative;
    color: white;
    opacity: 1;
    white-space: nowrap;
    font-family: monospace;
    font-weight: 601;
    line-height: 16px;
    font-size: 10px;
    overflow: hidden;
    width: 0;
}

.AlienMessageFadeIn {
    opacity: 1 !important;
}

.AlienMessageFadeOut {
    opacity: 0 !important;
}

@keyframes admin_alien {
    0% {transform: rotateY(0deg); text-shadow: rgb(91, 185, 61) 0 0 0;}
    10% {transform: rotateY(180deg); text-shadow: rgb(91, 185, 61) 0 0 40px;}
    20% {transform: rotateY(360deg); text-shadow: rgb(91, 185, 61) 0 0 0;}
    30% {transform: rotateY(540deg); text-shadow: rgb(91, 185, 61) 0 0 40px;}
    40% {transform: rotateY(720deg); text-shadow: rgb(91, 185, 61) 0 0 0;}
    50% {transform: rotateY(900deg); text-shadow: rgb(91, 185, 61) 0 0 40px;}
    60% {transform: rotateY(1080deg); text-shadow: rgb(91, 185, 61) 0 0 0;}
    70% {transform: rotateY(1260deg); text-shadow: rgb(91, 185, 61) 0 0 40px;}
    80% {transform: rotateY(1440deg); text-shadow: rgb(91, 185, 61) 0 0 0;}
    90% {transform: rotateY(1620deg); text-shadow: rgb(91, 185, 61) 0 0 40px;}
    100% {transform: rotateY(1800deg); text-shadow: rgb(91, 185, 61) 0 0 0;}
}

#AlienActionsWrap {
    width: 35px;
    z-index: 9999999999999;
    position: absolute;
    right: 62px;
    bottom: 145px;
    opacity: 1;
}

.AlienActionsFadeIn {
    opacity: 1 !important;
    pointer-events: all !important;
}

.AlienActionsFadeOut {
    opacity: 0 !important;
    pointer-events: none;
}

.AlienActionWrap {
    pointer-events: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-sizing: border-box;
    height: 35px;
    position: relative;
    background-color: var(--tones-white);
    border: 1px solid rgb(91, 185, 61);
    box-shadow: 0 3px 5px -1px rgba(91, 185, 61,0.2),0 6px 10px 0 rgba(91, 185, 61,0.14),0 1px 18px 0 rgba(91, 185, 61,0.12);
    cursor: pointer;
    margin-top: 15px;
    filter: invert(0%);
    opacity: 0;
    transition-property: filter, opacity;
    transition-duration: 0.1s, 0.3s;
    transition-timing-function: linear, linear;
}

.AlienActionOtherLoading {
    filter: saturate(0%);
    pointer-events: none     !important;
}

.AlienActionIsLoading {
    filter: invert(100%);
}

@keyframes alien-loading {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

.AlienAction {
    font-size: 13px;
    user-select: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AlienActionLoading {
    filter: invert(100%);
    animation: alien-loading 1.5s linear infinite;
}

.AlienActionLabel {
    pointer-events: none;
    user-select: none;
    position: absolute;
    width: max-content;
    right: 45px;
    font-family: monospace;
    background-color: white;
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 601;
    border: 1px solid rgb(91, 185, 61);
    box-shadow: 0 3px 5px -1px rgba(91, 185, 61,0.2),0 6px 10px 0 rgba(91, 185, 61,0.14),0 1px 18px 0 rgba(91, 185, 61,0.12);
}

#AlienIntermediateWrap {
    position: fixed;
    top: 0;
    left: 0;
    background-image:radial-gradient(100% 100% at 50% 50%, rgba(91, 185, 61, 0.1) 20%, rgba(91, 185, 61, 0.16) 26.67%, rgba(91, 185, 61, 0.23) 33.33%, rgba(91, 185, 61, 0.29) 40%, rgba(91, 185, 61, 0.35) 46.67%, rgba(91, 185, 61, 0.41) 53.33%, rgba(91, 185, 61, 0.48) 60%, rgba(91, 185, 61, 0.54) 66.67%, rgba(91, 185, 61, 0.6) 73.33%, rgba(91, 185, 61, 0.66) 80%, rgba(91, 185, 61, 0.73) 86.67%, rgba(91, 185, 61, 0.85) 100%);    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;
    box-sizing: border-box;
    backdrop-filter: blur(10px) invert(100%);
}

#AlienIntermediateWrap > div {
    position: relative;
    background-color: black;
    border: 1px solid rgb(91, 185, 61);
    border-radius: 3px;
    padding: 20px 20px 40px 20px;
    min-height: 100px;
    width: 400px;
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    box-sizing: border-box;
}

#AlienIntermediateWrap p {
    font-family: monospace !important;
    line-height: 16px;
}

#AlienIntermediateClose, #AlienIntermediateProceed {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    color: lime;
    font-family: monospace !important;
}

#AlienIntermediateProceed {
    width: unset;
    top: unset;
    margin-right: 20px;
    bottom: 0;
}

#AlienIntermediateContent {
    width: 100%;
}

#AlienIntermediateCustomShip, #AlienIntermediateCustomLeadTime {
    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.AlienIntermediateMarginTop {
    margin-top: 15px;
}

#AlienIntermediateCustomShip > input, #AlienIntermediateCustomLeadTime > input, #AlienIntermediateDismissibleNote > textarea, #AlienIntermediateAssumeUserIdentity > input {
    outline: none;
    border: 1px solid rgb(91, 185, 61);
    border-radius: 3px;
    padding: 3px 10px;
    background-color: black;
    font-family: monospace !important;
    margin-top: 15px;
    text-align: end;
    font-size: 12px;
    color: lime;
}

#AlienIntermediateDismissibleNote > textarea {
    margin-top: 15px;
    padding: 10px;
    resize: none;
    width: 100% !important;
    height: 200px !important;
    box-sizing: border-box;
    text-align: start;
}

#AlienIntermediateAssumeUserIdentity {
    width: calc(100% - 20px);
}

#AlienIntermediateAssumeUserIdentity > input {
    width: 60% !important;
    margin-top: 15px;
    text-align: left;
}

#AlienIntermediateWarning {
    color: red;
    font-family: monospace !important;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 20px;
    font-size: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #AlienButtonWrap:hover {
        filter: invert(100%);
    }

    .AlienActionWrap:hover {
        filter: invert(100%);
    }

    #AlienIntermediateClose:hover, #AlienIntermediateProceed:hover {
        color: white;
    }
}