#UploadHeader {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--tones-white);
}

#UploadHeaderTitleWrap, #HowItWorksWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

#UploadHeaderTitleWrap > h1 {
    margin-bottom: 5px;
}

#SelectTechnologyWrap {
    width: 100%;
    margin-top: 35px;
    border-radius: 3px;
    background-color: var(--tones-white);
    border: var(--border-main);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

#SelectTechnologyLeft {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 20px;
    flex: 2;
}

.StepReference {
    margin-bottom: 3px;
}

#SelectTechnologyTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#SelectTechnologyTitle > h3 {
    margin-right: 10px;
}

#SelectTechnologyHelp {
    font-size: 18px;
    margin-left: 12px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    cursor: pointer;
}

#SelectTechnologySelectLeft {
    pointer-events: none;
}

#TechnologySelectNames {
    color: var(--tones-dark-grey);
    margin-top: 3px;
}

#SelectTechnologySelect {
    user-select: none;
    position: relative;
    border-left: var(--border-main);
    flex: 1;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
    white-space: nowrap;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    box-sizing: border-box;
}

.TechnologyListOptionP {
    color: var(--tones-dark-grey);
    margin-top: 3px;
}

.SelectTechnologySelectHover {
    background-color: var(--blues-light);
}

#SelectTechnologyIcon {
    font-size: 30px;
    pointer-events: none;
    transform: rotate(0deg);
    transition: transform 0.2s;
}

.OpenIcon {
    transform: rotate(-180deg) !important;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #SelectTechnologyHelp:hover {
        color:  var(--blues-main);
    }
}