.QuotePartWrap {
    border-bottom: var(--border-main);
    border-left: var(--border-main);
    border-right: var(--border-main);
    position: relative;
    width: 100%;
    background-color: var(--tones-white);
    opacity: 1;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.QuotePartWrapCollapsedBorder {
    position: absolute;
    top: -1px;
    left: -1px;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    border: 2px solid var(--blues-light);
    z-index: 1;
    pointer-events: none;
}

.QuotePartWrapCollapsedBorder::before {
    content: ''; /* Required for pseudo-elements to display */
    position: absolute;
    top: 0; /* Adjustments to align the border just outside the element's dashed border */
    left: 0;
    right: 0;
    bottom: 0;
    border: var(--border-blue);
    border-width: 2px;
    border-style: dashed;
    box-sizing: border-box; /* Ensures the border width is included in the element's total width and height */
}

.DeletingPart {
    transition: opacity 0.3s, background-color 0.3s, transform 0.3s;
    z-index: 1;
    opacity: 0;
    transform: translateX(10px);
    background-color: var(--warning-light);
    box-shadow: 0 1px 0 var(--tones-grey);
}

.DeletingPart + .QuotePartWrap {
    box-shadow: 0 1px 0 var(--tones-grey) inset;
}

.QuotePart {
    position: relative;
    height: 155px;
    transition: background-color 0.3s;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.QuotePartWarningBackground {
    background-color: var(--warning-trans);
}

.QuotePartHideBorderBottom {
    border-bottom: var(--border-white);
}

.QuotePartHideBorderRight {
    border-right: var(--border-white);
}

.PartIndex {
    background-color: var(--blues-light);
    border-radius: 2px;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.PartThumbnailWrap {
    position: relative;
    height: 130px;
    box-sizing: border-box;
    width: 130px;
    min-width: 130px;
    flex: 1;
    margin-left: 20px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 3px;
    background-color: transparent;
}

.PartThumbnailWrapNoHover {
    cursor: initial;
}

.PartThumbnail {
    height: inherit;
    width: inherit;
    opacity: 0;
    transition: opacity 0.2s;
    mix-blend-mode: multiply;
}

@keyframes loading {
    0% {transform: rotate(0deg)}
    30% {transform: rotate(320deg)}
    35% {transform: rotate(360deg)}
    40% {transform: rotate(400deg)}
    95% {transform: rotate(700deg)}
    100% {transform: rotate(720deg)}
}

@keyframes scale {
    from {transform: scale(1)}
    to {transform: scale(1.8)}
}

.ImgThumbnailHover {
    opacity: 0 !important;
}

.PartThumbnailLoadingWrap, .OpenPartViewer {
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    height: inherit;
    border-radius: 3px;
    width: inherit;
    z-index: 1;
    background-color: var(--blues-trans);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.2s;
}

.PartLoadingWrap {
    position: absolute;
    top: -1px;
    left: -1px;
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    overflow: hidden;
    z-index: 7;
    background-color: var(--blues-trans);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.PartLoadingStatusWrap {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 8;
}

.PartLoadingStatusWrap > p {
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
}

.PartInfoOverlay {
    pointer-events: none;
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.4s;
}

.PartInfoOverlayFadeout {
    opacity: 0;
}

.PartLoadingStatus {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 10px;
    font-weight: 600;
    background-color: var(--blues-light);
    padding: 2px 26px 5px 4px;
    border-top-right-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 90%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.4s;
}

.PartLoadingStatus > p:last-child {
    position: absolute;
    right: 0;
    width: 25px;
    height: 100%;
    padding-left: 5px;
    box-sizing: border-box;
    background-color: inherit;
    display: flex;
    align-items: center;
}

.PartLoadingStatus > p:last-child:after {
    content: ' .';
    animation: dots1 1s steps(5, end) infinite;
}

.PartLoadingStatusConverting {
    background-color: var(--warning-light-converting);
}

.QuotePartLastChild {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.PartLoadingBar {
    position: absolute;
    height: 3px;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    background-color: var(--blues-light);
    transition: background-color 0.4s;
}

.PartLoadingBar > div {
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: var(--blues-main);
    transform: translateX(-100%);
    transition: transform 0.2s linear, background-color 0.4s;
}

.PartLoadingBarConverting {
    background-color: var(--warning-light-converting);
}

@keyframes converting {
    from {transform: translateX(-110%)}
    to {transform: translateX(110%)}
}

.PartLoadingBarConverting > div {
    background-color: var(--warning-converting);
}

.OpenPartViewer {
    opacity: 0;
    background-color: var(--blues-main);
}

.OpenPartViewerHover {
    opacity: 1;
}

.OpenPartViewerIcon {
    font-size: 18px;
    color: var(--blues-main);
    animation: loading 2s linear infinite;
}

.OpenPartViewerIcon {
    animation: none;
    color: var(--tones-white);
    transform: scale(1.8);
}

.OpenPartViewerHoverIcon {
    animation: scale 0.3s ease-out 1;
}

.PartInfoWrap {
    height: 70%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    justify-content: space-between;
    flex: 2;
    margin-left: 20px;
}

.PartInfoWrap > h3 {
    text-overflow: ellipsis;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 190px;
}

.PartInfoWrap p {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--tones-dark-grey);
}

.PartInfoWrap div {
    flex: 1;
}

.PartInfoWrap p > span {
    cursor: pointer;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    filter: brightness(100%);
}

.PartInfoWrap p {
    user-select: none;
}

.PartInfoWrap p > span:first-of-type {
    color: var(--blues-main);
}

.CloneDeleteP {
    margin-top: 5px;
}

.CloneLoading {
    pointer-events: none;
    color: var(--tones-dark-grey) !important;
}

.ApplyAllPDisabled, .ApplyAllPDisabled > span {
    cursor: initial !important;
}

.ApplyAllPDisabled > span {
    color: var(--tones-dark-grey) !important;
}

.TechnologyFinishWrap {
    height: 65%;
    flex: 3;
    box-sizing: border-box;
}

.TechnologyFinishWrapRoot {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
    overflow: hidden;
    border-radius: 3px;
    transition: border 0.2s;
    cursor: pointer;
}

.TechnologyFinishWrapRootDisabled {
    cursor: initial;
}

.TechnologyFinishInfo {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.TechnologyFinishInfo > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
    height: 30%
}

.TechnologyFinishInfo > div > p:first-of-type {
    width: 100px;
    text-align: right;
    margin-right: 20px;
}

.TechnologyFinishInfo > div > p:nth-of-type(2), .TechnologyFinishInfo > div > div > p {
    color: var(--tones-dark-grey);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 167px;
}

.TechnologyFinishInfo > div > div > p {
    width: 110px;
}

.TechnologyFinishInfo > div > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.TechnologyFinishEdit {
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-main);
    user-select: none;
    opacity: 0;
    transition: filter 0.3s, opacity 0.2s, background-color 0.3s, color 0.3s;
    color: var(--tones-white);
}

/* hover over entire quote part (triggered via js) */
/* or if the user is on a mobile / tab */
.QuotePartWrap[quote-part-hovered="true"] .TechnologyFinishWrapRoot:not(.TechnologyFinishWrapRootCollapsed):not(.TechnologyFinishWrapRootDisabled), .TechnologyFinishWrapRootMobile {
    border: var(--border-main);
}

.QuotePartWrap[quote-part-hovered="true"] .TechnologyFinishWrapRoot:not(.TechnologyFinishWrapRootCollapsed):not(.TechnologyFinishWrapRootDisabled) .TechnologyFinishEdit, .TechnologyFinishWrapRootMobile .TechnologyFinishEdit {
    opacity: 1;
}

.TechnologyFinishWrapRootCollapsed:not(.TechnologyFinishWrapRootMobile) {
    pointer-events: none;
    cursor: initial;
}

.QuantityPriceWrap {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
    min-width: 400px;
    height: 100%;
}

.PartPriceWrap {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 20px;
    user-select: none;
}

.PartPriceWrap > div:not(.PartWarningPriceStatement) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 160px;
}

.PartPriceWrap > div:not(.PartWarningPriceStatement) > p {
    color: var(--tones-dark-grey);
}

.PartPriceWrap > div:not(.PartWarningPriceStatement):last-of-type > p {
    color: var(--blues-main);
}

.PartElemLocked {
    pointer-events: none;
}

.QuotePartWarning {
    position: absolute;
    top: 7px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 23px;
    z-index: 6;
    background-color: var(--warning-light);
    padding-right: 5px;
    border-radius: 3px;
}

.QuotePartReqForWarning {
    background-color: var(--warning-light-sick-yellow);
}

.QuoteWarningOverlayBackground {
    background: linear-gradient(90deg, #DE000014, #DE000014, #DE000014, #DE000014, #DE000014, #DE000014, #DE000014, #DE000014, #DE000014, rgba(0, 0, 0, 0)) !important;
}

.QuotePartWarningIconWrap {
    border-radius: 3px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning-main);
}

.QuotePartWarningIcon {
    color:var(--tones-white);
    font-size: 16px !important;
}

.QuotePartReqForWarning > .QuotePartWarningIconWrap {
    background-color: var(--warning-sick-yellow);
}

.QuotePartReqForWarning > .QuotePartWarningIconWrap > .QuotePartWarningIcon {
    color: var(--tones-black)
}

.QuotePartWarning > p {
    color:var(--tones-black);
}

.QuotePartWarning > p > span, .QuotePartRequestForQuote > p > span {
    text-decoration: underline;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
}

.QuotePartWarning > p:not(.QuotePartRequestForQuote) > span:first-of-type {
    cursor: initial;
    margin-right: 5px;
    text-decoration: none !important;
}

.PartWarningPriceStatement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 160px;
    height: 100%;
}

.PartWarningPriceStatement > p {
    color: var(--tones-dark-grey);
    line-height: 20px;
}

.PartWarningPriceStatement > p > span, .PartWarningPriceStatement > p > a {
    color: var(--blues-main);
    cursor: pointer;
    text-decoration: none;
}

.QuotePartLeft {
    box-sizing: border-box;
    flex: 2;
}

.QuotePartRight {
    box-sizing: border-box;
    border-left: var(--border-main);
    flex: 1;
    background-color: var(--tones-white);
}

.QuotePartCollapse .PartInfoWrap {
    width: 200px;
    min-width: 200px;
}

.QuotePartSplit {
    flex-wrap: wrap;
    height: auto;
}

.QuantityPriceWrapSplit {
    margin-left: auto;
}

.QuotePartSplit .PartThumbnailWrap {
    margin: 10px 0 0 20px;
}

.QuotePartSplit .PartInfoWrap {
    align-items: flex-end;
    height: 110px;
    margin: 10px 20px 0 0;
    width: calc(100% - 190px) !important;
    min-width: unset;
    max-width: unset;
}

.QuotePartSplit .PartInfoWrap h3 {
    width: 100%;
    text-align: end;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.QuotePartSplit .PartInfoWrap > div {
    text-align: end;
}

.QuotePartSplit .QuantityPriceWrap {
    width: 100%;
    height: 100px;
    margin: 0 0 10px 0;
    align-items: flex-end;
}

.QuotePartSplit .QuantityInput {
    margin: 0 0 31px 20px;
}

.QuotePartSplit .PartPriceWrap {
    height: 80%;
    margin: 0 20px 0 0;
}

.QuotePartSplit .PartWarningPriceStatement {
    position: relative;
    top: -15px;
    text-align: end;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .PartInfoWrap p > span:hover {
        filter: brightness(130%);
        color: var(--blues-main);
    }

    .ApplyAllPDisabled:hover, .ApplyAllPDisabled:hover > span  {
        filter: brightness(100%) !important;
    }

    .PartWarningPriceStatement > p > span:hover, .PartWarningPriceStatement > p > a:hover {
        filter: var(--filter-brightness);
    }

    .TechnologyFinishEdit:hover {
        filter: var(--filter-brightness);
    }
}