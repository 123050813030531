#UserTableNicknameWrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 51;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    animation: fadeIn 0.5s linear 1;
}

#UserTableNickname {
    box-sizing: border-box;
    position: relative;
    width: 300px;
    background-color: var(--tones-white);
    border-radius: 5px;
    border: var(--border-main);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    box-shadow: var(--box-shadow-main);
}

#UserTableNickname > p {
    width: 100%;
    color: var(--tones-dark-grey);
    margin-bottom: 12px;
}

.UserTableNicknameWarning {
    color: var(--warning-main) !important;
}

#UserTableNicknameModalClose {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    color: var(--tones-black);
}

#UserTableNickname > input {
    height: 40px;
    border: var(--border-main);
    padding: 0 20px;
    width: calc(100% - 42px);
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    font-size: 13px;
    background-color: var(--blues-light);
    margin-bottom: 10px;
    border-radius: 3px;
    outline: none;
}

#UserTableNickname > input:focus {
    border-color: var(--blues-main);
}

#UserTableNickname > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

#UserTableNickname > div > button {
    background-color: var(--blues-main);
    white-space: nowrap;
    padding: 13px 30px;
    font-size: 13px;
    font-weight: 401;
    color: var(--tones-white);
    border-radius: 3px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-items: center;
    user-select: none;
    cursor: pointer;
    margin-left: 20px;
    transition: filter 0.3s;
    filter: brightness(100%);
}

#UserTableNickname > div > button:first-of-type {
    background-color: var(--warning-main) !important;
}