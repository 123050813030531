#QuoteOverlayWarningModalWrap {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
}

#QuoteOverlayWarningModalWrap > div {
    position: relative;
    width: 570px;
    box-sizing: border-box;
    height: 425px;
    border-radius: 4px 5px 4px 4px;
    background-color: var(--tones-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-main);
}

#QuoteOverlayWarningModalCloseButton {
    position: absolute;
    border: none;
    outline: none;
    top: 0;
    right: 0;
    height: 50px;
    z-index: 2;
    width: 50px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 4px;
    background-color: var(--tones-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

#QuoteOverlayWarningModalCloseButtonIcon {
    font-size: 22px;
    color: var(--tones-dark-grey);
    transition: color 0.2s;
}

#QuoteOverlayWarningModalIcon {
    color: var(--warning-yellow);
    font-size: 50px;
}

#QuoteOverlayWarningModalWrap > div > p {
    margin-top: 15px;
    text-align: center;
    color: var(--tones-dark-grey);
}

#QuoteOverlayWarningModalWrap > div > p:last-of-type {
    margin-top: 0 !important;
    margin-bottom: 15px;
    width: 60%;
}

#QuoteOverlayWarningModalOptionsWrap {
    width: 84%;
    border: var(--border-main);
    box-sizing: border-box;
    margin: 15px 0;
}

.QuoteOverlayWarningModalOption {
    width: 100%;
    height: 70px;
    user-select: none;
    border-bottom: var(--border-main);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: var(--tones-white);
    transition: background-color 0.3s;
    overflow: hidden;
    cursor: pointer;
}

.QuoteOverlayWarningModalOptionSelected {
    background-color: var(--blues-light);
}

.QuoteOverlayWarningModalOption > div {
    pointer-events: none;
}

.QuoteOverlayWarningModalOption:last-of-type {
    border-bottom: none;
}

.QuoteOverlayWarningModalCheckbox {
    flex: 1;
    margin-right: 15px !important;
    padding: 0 !important;
}

.QuoteOverlayWarningModalOptionDisabled {
    pointer-events: none;
    filter: grayscale(100%)
}

.QuoteOverlayWarningModalCheckbox .MuiSvgIcon-root {
    width: 19px;
    height: 19px;
    border-radius: 3px;
    color: var(--blues-main);
}

.QuoteOverlayWarningModalOption > div {
    height: 100%;
    flex: 20;
}

#QuoteOverlayWarningModalTechMatWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#QuoteOverlayWarningModalTechMatWrap > p {
    color: var(--tones-dark-grey);
    line-height: 24px;
    white-space: nowrap;
}

#QuoteOverlayWarningModalTechMatWrap > p > span {
    width: 67px;
    text-align: end;
    display: inline-block;
    margin-right: 7px;

}

#QuoteOverlayWarningModalQuantityWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#QuoteOverlayWarningModalQuantityWrap > p {
    color: var(--tones-dark-grey);
}

#QuoteOverlayWarningModalWrap > div > div > button {
    background-color: var(--blues-main);
    border: var(--border-blue);
    border-radius: 3px;
    outline: none;
    padding: 10px 25px;
    color: var(--tones-white);
    cursor: pointer;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    white-space: nowrap;
    overflow: hidden;
}

#QuoteOverlayWarningModalWrap > div > div > button:last-of-type {
    background-color: var(--tones-white);
    margin-left: 15px;
    color: var(--tones-black);
}

.QuoteOverlayWarningModalButtonDisabled {
    pointer-events: none;
    filter: saturate(0%);
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #QuoteOverlayWarningModalCloseButton:hover {
        background-color: var(--blues-main);
    }

    #QuoteOverlayWarningModalCloseButton:hover > #QuoteOverlayWarningModalCloseButtonIcon {
        color: var(--tones-white);
    }

    .QuoteOverlayWarningModalOption:not(.QuoteOverlayWarningModalOptionSelected):hover {
        background-color: var(--blues-light);
    }

    #QuoteOverlayWarningModalWrap > div > div > button:not(:last-of-type):hover {
        filter: var(--filter-brightness);
    }
}