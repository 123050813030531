#UserHeader {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--tones-white);
}

#UserHeaderTitleWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

#UserHeaderTitleWrap > h1 {
    margin-bottom: 5px;
}

.UserSection {
    position: relative;
    width: 100%;
    border-radius: 3px;
    background-color: var(--tones-white);
    border: var(--border-main);
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.UserSectionMarginTop  {
    margin-top: 26px;
}

.UserSectionHeader {
    width: 100%;
    height: 50px;
    background-color: var(--tones-light-grey);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: var(--border-main);
}

.UserSectionTitle {
    margin-left: 36px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.UserSectionTitle > span {
    font-weight: normal;
    text-align: end;
    margin-right: 36px;
}

.UserSectionContent {
    width: 100%;
    padding: 10px 36px;
    box-sizing: border-box;
}

.UserSectionButtonWrap {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    align-items: center;
}

.UserSectionButtonWrap > button, #UserDeleteAreYouSureModalButtonWrap > button {
    background-color: var(--blues-main);
    white-space: nowrap;
    padding: 13px 30px;
    color: var(--tones-white);
    border-radius: 3px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-items: center;
    user-select: none;
    cursor: pointer;
    margin-left: 20px;
    transition: filter 0.3s;
    filter: brightness(100%);
}

#UserDeleteAreYouSureModalButtonWrap > button {
    margin-left: unset !important;
}

.UserButtonDisabled {
    pointer-events: none;
    filter: brightness(0%);
}

.UserRedButton {
    background-color: var(--warning-main) !important;
}

#DeleteUserWrap {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

#DeleteUserButton {
    position: relative;
    background-color: var(--warning-main);
    color: var(--tones-white);
    padding: 15px;
    white-space: nowrap;
    width: 240px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: filter 0.3s;
    filter: brightness(100%);
}

.UserInfoElem {
    position: relative;
    min-height: 60px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.UserInfoElem:first-of-type {
    margin-top: 30px;
}

.UserInfoElem > h4 {
    flex: 1;
    display: flex;
    align-items: center;
}

.UserInfoElem > p {
    color: var(--tones-dark-grey);
    flex: 3;
    display: flex;
    align-items: center;
}

.UserInfoElem > div:not(.UserInfoMulti) {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}

.UserInfoMulti {
    flex: 3;
}

.UserInfoInputWrap {
    flex: 3;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.UserInfoMultiInputWrap {
    flex: 3;
}

.UserInfoInputWrap > input, .UserInfoMultiInputWrap > input {
    color: var(--tones-dark-grey);
    border-radius: 3px;
    height: 32px;
    outline: none;
    border: var(--border-main);
    padding: 0 10px;
    width: 40%;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    background-color: var(--blues-light);
    transition: border 0.3s;
    box-sizing: border-box;
}

.UserInfoInputWrap > input:focus, .UserInfoMultiInputWrap > input:focus {
    border: var(--border-blue);
}

.UserInfoMultiInputWrap > input {
    margin-bottom: 30px;
    display: block;
}

.UserInfoMultiInputWrap > p, .UserInfoInputWrap > p {
    white-space: nowrap;
    color: var(--warning-main);
    margin-bottom: 10px;
}

.UserInfoElemDivider {
    border: none;
    border-top: var(--border-main);
    box-sizing: border-box;
    margin: 30px 0;
}

.UserInfoElemDividerHidden {
    border-top: 1px rgba(0, 0, 0, 0) solid;
    box-sizing: border-box;
    border: none;
    margin: 30px 0;
}

.UserChangeCurrencyRoot {
    width: 40%;
    border: var(--border-main) !important;
    border-radius: 3px;
    padding: 10px 0 !important;
    box-sizing: unset;
    transition: border 0.3s;
}

.UserChangeCurrencyRoot:focus {
    border: var(--border-blue);
}

.UserChangeCurrencySelect {
    color: var(--tones-dark-grey) !important;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    padding: unset !important;
    outline: none;
    padding-left: 15px !important;
}

.MarginBottomInfoElem {
    margin-bottom: 30px;
}

.UserChangeCurrencySelect[aria-expanded="true"] {
    background-color: var(--tones-white) !important;
}

.UserChangeCurrencySelect:focus {
    background-color: var(--tones-white) !important;
}

.UserSectionAssumingSpan {
    color: midnightblue;
    text-shadow: 1px 1px lime;
    font-size: 12px !important;
    font-family: monospace !important;
    font-weight: normal;
    margin-left: 10px;
}

.TradeAccountWrap {

}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .UserSectionButtonWrap > button:hover {
        filter: var(--filter-brightness);
    }
}