#DismissibleNoteModalWrap {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
}

#OverlayWarningModalIcon {
    color: var(--warning-yellow);
    font-size: 50px;
}

#DismissibleNoteModalWrap > div {
    position: relative;
    width: 520px;
    box-sizing: border-box;
    height: 425px;
    border-radius: 4px 5px 4px 4px;
    background-color: var(--tones-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-main);
}

#DismissibleNoteModalWrap > div {
    height: unset;
    justify-content: flex-start;
    padding: 20px;
    width: 580px;
}

#DismissibleNoteModalWrap > div > p {
    margin-top: 15px;
    text-align: center;
    color: var(--tones-dark-grey);
}

#DismissibleNoteModalNoteWrap {
    margin-top: 20px;
    width: 100%;
}

#DismissibleNoteModalNoteWrap > p {
    white-space: pre-wrap;
    text-align: center;
    line-height: 25px;
    color: var(--tones-dark-grey);
    max-height: 200px;
    overflow: auto;
}

#DismissibleNoteModalButtonWrap {
    margin-top: 20px;
}

#DismissibleNoteModalButtonWrap > button {
    background-color: var(--blues-main);
    border: var(--border-blue);
    border-radius: 3px;
    outline: none;
    padding: 10px 25px;
    color: var(--tones-white);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
}