#UserInfoModal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 50;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    animation: fadeIn 0.5s linear 1;
}

#UserInfoModalClose {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
    color: var(--tones-black);
}

#UserInfoModalClose:hover {
    color: var(--blues-main);
}

#UserInfoInputWrap {
    position: relative;
    width: 500px;
    box-sizing: border-box;
    background-color: var(--tones-white);
    border-radius: 5px;
    box-shadow: var(--box-shadow-main);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

#UserInfoInputLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blues-trans);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

#UserInfoInputWrap > h3 {
    width: 100%;
    margin-bottom: 5px;
    color: var(--tones-dark-grey);
}

#UserInfoInputWrap > p {
    width: 100%;
    color: var(--tones-dark-grey);
    line-height: 16px;
}

#UserInfoInputWrap > form {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    margin: 10px 0 10px 0;
}

#UserInfoInputWrap > form > input, #UserInfoInputWrap > form > button {
    padding: 0;
    border: none;
    border-radius: 3px;
    outline: none;
}

#UserInfoInputWrap > form > input {
    height: 40px;
    border: var(--border-main);
    padding: 0 20px;
    width: calc(100% - 42px);
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    background-color: var(--blues-light);
    margin-bottom: 10px;
}

#UserInfoInputWrap > form > input:focus {
    border: var(--border-blue);
}

/* .UserInfoInputWrapLoggedIn {
    height: 260px !important;
}

.AdminInfoInputWrapAdmin {
    height: 210px !important;
} */

.UserInfoEmailWarning {
    border: var(--border-warning) !important;
}

#UserInfoInputWrap > form > div {
    color: var(--warning-main);
    align-self: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
}

#UserInfoButtonLoggedInWrap {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 10px 0;
}

#UserInfoInputWrap > form > button, #UserInfoButtonLoggedIn {
    border-radius: 3px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    width: 100%;
    border: none;
    outline: none;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.UserInfoFormButtonDisabled {
    pointer-events: none;
    background-color: var(--tones-grey) !important;
}

#UserInfoInputWrap > form > button:hover {
    background-color: var(--blues-dark);
}

#UserInfoInputWrap > div > p {
    color: var(--tones-dark-grey);
}

#UserInfoInputWrap > div > p > a {
    color: var(--tones-black);
}

#UserInfoInputWrap > div > ul > li {
    font-size: 12px;
    color: var(--tones-dark-grey);
}

#UserInfoInputWrap > p > a {
    color: var(--blues-main);
    font-weight: 501;
    text-decoration: underline;
    cursor: pointer;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

#UserInfoInputWrap > p > a:hover {
    filter: var(--filter-brightness);
}

.UserInfoButtonExtra {
    cursor: pointer;
    color: var(--blues-main);
}

#AdminEmail {
    margin-bottom: 10px;
}

#AdminEmail > span {
    color: var(--blues-main);
    cursor: pointer;
}


#UserInfoCurrencySelectorWrap > p {
    display: inline-block;
    color: var(--tones-dark-grey);
}

.UserInfoCurrencySelectorRoot {
    display: inline-block;
    font-size: 12px !important;
    color: var(--tones-black);
    text-decoration: underline;
    font-weight: 600 !important;
}

.UserInfoCurrencySelectorSelect {
    padding: 0 17px 0 0 !important;
}

.UserInfoCurrencySelectorIcon {
    font-size: 17px !important;
    color: var(--tones-black) !important;
    top: 0 !important;
}

.UserInfoCurrencySelectorSelect[aria-expanded="true"] {
    background-color: rgba(0, 0, 0, 0) !important;
}

.UserInfoCurrencySelectorSelect:focus {
    background-color: rgba(0, 0, 0, 0) !important;
}

#UserInfoModalConsentWrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#UserInfoModalConsentWrap > p {
    color: var(--tones-dark-grey);
}

#UserInfoConsentRequiredWarning {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    box-sizing: border-box;
}

.UserInfoConsentRequiredWarningActive {
    border-bottom: 2px solid var(--warning-main) !important;
}