#PartViewer {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
    z-index: 9999;
}

#PartViewer > div {
    background-color: var(--tones-white);
    border-radius: 3px;
    box-shadow: var(--box-shadow-main);
    box-sizing: border-box;
    height: calc(100% - 20px);
    position: relative;
    width: calc(100% - 20px);
    border: var(--border-main);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    /* max-width: none !important; */
    max-height: calc(675px + 3%);
    max-width: calc(1200px + 3%);
}

#PartViewerHeader {
    width: 100%;
    border-bottom: var(--border-main);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#PartViewerHeaderStartWrap {
    margin-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    max-width: calc(100% - 130px);
}

#PartViewerHeaderStartWrap > h4 {
    color: var(--tones-dark-grey);
}

#PartViewerHeaderStartWrap > h3 {
    text-overflow: ellipsis;
    overflow: hidden;
}

#ClosePartViewButton {
    background-color: var(--tones-white);
    height: 100%;
    padding: 12px 30px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0;
    transition: filter 0.3s;
    border-left: var(--border-main);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#ClosePartViewButtonIcon {
    font-size: 25px;
    color: var(--tones-black);
    transition: color 0.2s;
}

#PartViewerMount {
    flex: 1;
    width: 100%;
    animation: fadeIn 0.8s linear 1;
    position: relative;
}

#PartViewerLoadingWrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: var(--tones-grey);
    overflow: hidden;
    border-bottom-left-radius: 3px;
    pointer-events: none;
    border-bottom-right-radius: 3px;
    opacity: 1;
    transition: opacity 0.2s;
    z-index: 99;
}

.PartViewerLoadingWrapComplete {
    opacity: 0 !important;
}

#PartViewerLoadingP {
    position: absolute;
    bottom: 15px;
    left: 20px;
    color: var(--tones-dark-grey);
    opacity: 1;
    transition: opacity 0.2s;
    z-index: 99;
    pointer-events: none;
}

.PartViewerLoadingPError {
    color: var(--warning-main) !important;
}

.PartViewerLoadingPComplete {
    opacity: 0 !important;
}

#PartViewerLoadingWrap > div {
    width: 100%;
    height: 100%;
    background-color: var(--blues-main);
    transform: translateX(-100%);
}

#PartViewerUtilsWrap {
    pointer-events: none;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.PartViewerUtils {
    position: relative;
    box-sizing: border-box;
    box-shadow: var(--box-shadow-light);
    background-color: var(--tones-white);
    margin-bottom: 15px;
    border-radius: 3px;
    color: var(--tones-dark-grey);
    user-select: none;
    min-width: 175px;
    pointer-events: all;
    overflow: hidden;
    z-index: 15;
}

.PartViewerUtils:last-of-type {
    margin-bottom: 0;
}

.PartViewerUtilsHeader {
    transition: background-color 0.3s;
    padding: 15px;
    box-sizing: border-box;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.PartViewerUtilsOpen {
    border-bottom: var(--border-main);
}

.PartViewerUtilsExpandIcon {
    font-size: 16px;
    transform: rotate(0deg);
    transition: transform 0.3s !important;
}

.PartViewerUtilsOpen .PartViewerUtilsExpandIcon {
    transform: rotate(-180deg);
}

.PartViewerUtilsAction {
    padding: 8px 15px 8px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: background-color 0.3s;
}

.PartViewerUtilsAction > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 35px;
    width: 30px;
    margin-right: 10px;
}

.PartViewerActionIcon {
    stroke: var(--tones-dark-grey);
}

.PartViewerActionIconFill {
    fill: var(--tones-grey);
}

.PartViewerUtilsAction > p {
    transition: color 0.3s;
}

.SectionAnalysisAxisWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
}

.SectionAnalysisAxisWrap > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SectionAnalysisAxisWrap > div > p {
    margin-right: 15px;
}

.SectionAnalysisAxisWrap > p {
    width: 70px;
    margin-left: 15px;
    text-align: end;
    white-space: nowrap;
    cursor: pointer;
}

.HideAxisP {
    display: none;
}

.DisableAxisP {
    pointer-events: none;
}

.SectionAnalysisAxisInputWrap {
    width: 70px;
    box-sizing: border-box;
    margin-left: 15px;
    white-space: nowrap;
}

.SectionAnalysisAxisInputWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.SectionAnalysisAxisInput {
    border: none;
    outline: none;
    box-sizing: border-box;
    text-align: end;
    white-space: nowrap;
    color: var(--tones-dark-grey);
    width: 100%;
}

.SectionAnalysisAxisInputWrap > p {
    flex: 1;
    margin-right: unset !important;
    margin-left: 1px;
}

.HideAxisInput {
    display: none !important;
}

.SectionAnalysisAxisTrack {
    background-color: var(--blues-main) !important;
}

.SectionAnalysisAxisRail {
    color: var(--tones-grey) !important;
    height: 3px;
}

.SectionAnalysisAxisThumb {
    box-shadow: none !important;
    height: 7px !important;
    width: 3px !important;
    border-radius: 0 !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    top: 12px !important;
}

.SectionAnalysisHeader.PartViewerUtilsOpen {
    margin-bottom: 8px;
}

.SectionAnalysisAxisWrap:last-of-type {
    margin-bottom: 8px;
}

.SectionAnalysisAxisDisabled {
    pointer-events: none;
    filter: saturate(0%);
    cursor: initial;
}

.PartViewerUtilsSelectedAction {
    pointer-events: none;
    cursor: initial;
}

#ClearMeasurementsWrap {
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.ClearMeasurementsWrapHigher {
    bottom: 100px !important;
}

#ClearMeasurementsWrap > p {
    user-select: none;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s, background-color 0.3s;
    pointer-events: none;
    cursor: pointer;
    color: var(--tones-dark-grey);
    padding: 5px 18px;
    border-radius: 5px;
    background-color: var(--tones-white);
    box-shadow: var(--box-shadow-light);
}

.ClearMeasurementsVisible {
    opacity: 1 !important;
    pointer-events: all !important;
}

.PartViewerLabel {
    position: absolute;
    background-color: var(--blues-main);
    color: var(--tones-white);
    border-radius: 3px;
    padding: 2px 8px;
    text-align: center;
    top: -5px;
    left: 0;
    transform: translateX(0px) translateY(0px);
}

.PartViewerLabel:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -2.5px;
    width: 0;
    height: 0;
    border-top: solid 2px var(--blues-main);
    border-left: solid 2px transparent;
    border-right: solid 2px transparent;
}

#PartViewerTopLeftInfo {
    position: absolute;
    top: 20px;
    left: 20px;
}

#PartViewerTopLeftInfo > p {
    margin: 0;
    color: var(--blues-main);
    opacity: 0;
    transition: opacity 0.3s;
}

.PartViewerConvertedWarningWrap {
    position: absolute;
    bottom: 20px;
    left: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
}

.PartViewerConvertedWarning {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 25px;
    z-index: 1;
    background-color: var(--warning-light-converting);
    padding-right: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    min-width: calc(100% - 5px);
}

.PartViewerConvertedWarningIconWrap {
    border-radius: 3px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning-converting);
}

.PartViewerConvertedWarningIcon {
    color: var(--tones-white);
    font-size: 16px !important;
}

.PartViewerConvertedWarning > p {
    border-radius: 3px;
    color: var(--tones-black) !important;
    padding: 5px;
    white-space: nowrap;
    margin-left: 5px;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #ClosePartViewButton:hover {
        background-color: var(--blues-dark);
    }

    #ClosePartViewButton:hover > #ClosePartViewButtonIcon {
        color: var(--tones-white);
    }

    .PartViewerUtilsHeader:hover {
        background-color: var(--blues-light);
    }

    .PartViewerUtilsAction:hover {
        background-color: var(--blues-light);
    }

    .PartViewerUtilsAction:hover > p {
        color: var(--blues-main);
    }

    .ClearMeasurementsVisible:hover {
        color: var(--blues-main) !important;
        background-color: var(--blues-light) !important;
    }
}