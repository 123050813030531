#Challenge {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00112e;
    z-index: 10;
}

#ChallengeContentWrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ChallengeContent {
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    width: 500px;
    background-color: var(--tones-white);
    padding: 30px;
}

#ChallengeClose {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
    color: var(--tones-black);
}

#ChallengeContent > hr {
    margin-bottom: 8px;
}

#ChallengeContent > p:first-of-type {
    margin-bottom: 5px;
}

#ChallengeLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blues-trans);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

#ChallengeContent > form {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    margin: 10px 0;
}

#ChallengeContent > form > input, #ChallengeContent > form > button, #ChallengeLogout {
    padding: 0;
    border: none;
    border-radius: 3px;
    outline: none;
}

#ChallengeContent > form > input {
    height: 40px;
    border: var(--border-main);
    padding: 0 20px;
    width: calc(100% - 42px);
    background-color: var(--blues-light);
    margin-bottom: 10px;
}

#ChallengeContent > form > input:focus {
    border: var(--border-blue);
}

#ChallengeContent > form > div {
    color: var(--warning-main);
    align-self: flex-start;
}

#ChallengeContent > form > button, #ChallengeLogout, #ChallengeEmail {
    border-radius: 3px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    width: 100%;
    border: none;
    outline: none;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 10px;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #ChallengeClose:hover {
        color: var(--blues-main);
    }

    #ChallengeContent > form > button:hover, #ChallengeLogout:hover {
        background-color: var(--blues-dark);
    }
}