#UserTableHeader {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: var(--tones-white);
}

#UserTableSearchWrap {
    position: relative;
}

#UserTableSearchSwitch {
    position: absolute;
    bottom: 0;
    left: -28px;
    background-color: var(--tones-white);
    border-radius: 50%;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: var(--border-main);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#UserTableSearchSwitchIcon {
    color: var(--tones-dark-grey);
    font-size: 18px !important;
    transform: rotateY(180deg);
    transition: transform 0.3s;
}

#UserTableSearchModeCancel {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 16px;
    color: var(--tones-dark-grey);
    cursor: pointer;
}

.UserTableSearch {
    padding: 10px 20px;
    border-radius: 3px;
    border: var(--border-thick);
    outline: none;
}

.UserTableSearch:focus {
    border-color: var(--blues-main);
}

.UserTableSearchDisabled {
    background-color: var(--tones-grey);
}

.UserTableSearchActive {
    border: var(--border-thick);
    border-color: var(--success-main);
}

#UserTableHeaderTitleWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

#UserTableMain {
    position: relative;
    width: 100%;
    margin-top: 35px;
    border-radius: 3px;
    background-color: var(--tones-white);
    border: var(--border-main);
    margin-bottom: 25px;
    box-sizing: border-box;
}

#UserTableLoading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--blues-trans);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

#UserTableMainHeadersWrap {
    width: 100%;
    height: 50px;
    background-color: var(--tones-light-grey);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: var(--border-main);
    overflow: hidden;
    white-space: nowrap;
    padding: 0 26px;
}

.UserTableMainHeader {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.UserTableMainHeader > h3 {
    white-space: nowrap;
}

.UserTableMainHeaderIcon {
    font-size: 20px !important;
    margin-left: 10px;
    cursor: pointer;
    color: var(--blues-main);
}

.UserTableSortStarIconFulfilled {
    color: gold !important;
    animation: glow 2.4s ease-in-out infinite;
}

#UserTableNoData {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserTableRow {
    position: relative;
    box-sizing: border-box;
    height: 80px;
    width: 100%;
    border-bottom: var(--border-main);
    display: flex;
    align-items: center;
    padding: 0 26px;
}

.UserTableRow:not(.UserTableRowIsSkeleton) {
    cursor: pointer;
    transition: background-color 0.3s;
}

.UserTableRowLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blues-trans);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.UserTableRowSelected {
    background-color: var(--blues-trans);
}

.UserTableIconWrap {
    position: absolute;
    height: 20px;
    top: 4px;
    left: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.UserTableRowIcon {
    font-size: 18px !important;
    margin-right: 1px;
}

.UserTableFavourite {
    color: gold;
}

.UserTableLocked, .UserTableUnderReview {
    color: var(--blues-main);
}

.UserTableRowLink:last-child > .UserTableRow, .UserTableRowIsSkeleton:last-child {
    border-bottom: none;
}

.UserTableRowCell {
    box-sizing: border-box;
    padding: 0 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
}

.UserTableRowOptions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UserTableRowOptionsDisabled {
    pointer-events: none;
}

.UserTableRowActions {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border: var(--border-main);
    padding: 0 20px;
    border-radius: 3px;
    height: 38px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background-color: var(--tones-white);
}

.UserTableRowActions > p {
    margin-right: 5px;
    pointer-events: none;
    color: var(--tones-black) !important;
}

.UserTableActionsIcon {
    color: var(--tones-dark-grey);
    transform: rotate(0deg);
    transition: transform 0.2s !important;
    pointer-events: none;
}

.OpenActions > .UserTableActionsIcon {
    transform: rotate(-180deg) !important;
}

.UserTableStatusCellPlaced::after {
    content: 'ⓘ';
    font-size: 10px;
    font-weight: 501;
    position: relative;
    top: -5px;
    left: 5px;
    color: var(--blues-main);
}

.UserTableRowCell > p, .UserTableRowStatusCell > p, .UserTableRowLink > p {
    color: var(--tones-black);
}

.UserTableRowLink {
    text-decoration: none;
}

.UserTableRowCell > p:nth-child(2), .UserTableRowStatusCell > p:nth-child(2), .UserTableRowLink > p:nth-child(2) {
    color: var(--tones-dark-grey);
}

.UserTableRowTotal {
    color: var(--blues-main) !important;
}

.UserTableRowOptionsIcon {
    color: var(--tones-dark-grey);
    cursor: pointer;
}

#UserTableSettingsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 380px;
    margin-bottom: 50px;
}

#UserTableSettingsWrap p {
    color: var(--tones-dark-grey);
    user-select: none;
}

.UserTableSettingsDisabled {
    pointer-events: none;
    color: var(--tones-grey) !important;
}

#UserTableSettingsWrap p:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
}

#UserTableSettingsArrows {
    display: flex;
    align-items: center;
}

.UserTableSettingsIcon {
    cursor: pointer;
    font-size: 19px !important;
    color: var(--tones-dark-grey);
    margin-right: 7px;
}

.UserTableSettingsIcon:last-child {
    margin-right: initial;
}

#UserTableSettingsRPP {
    display: flex;
    align-items: center;
    justify-content: center;
}

#UserTableSettingsRPP p {
    margin-right: 10px;
}

#UserTableSettingsRPP select {
    color: var(--tones-dark-grey);
    font-size: 13px;
    font-weight: 401;
    width: 55px;
    text-align: end;
    outline: none;
    background-color: var(--tones-white);
    border: var(--border-main);
    border-radius: 3px;
}

.UserTableOptionsMenu {
    padding: 0;
}

.UserTableOption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 42px;
    box-sizing: border-box;
    width: 280px;
    padding: 10px;
    cursor: pointer;
    background-color: var(--tones-white);
    border-bottom: var(--border-main);
}

.UserTableOption > p {
    user-select: none;
}

.UserTableOptionAlien {
    background-color: rgb(118, 236, 79) !important;
}

.UserTableRowAdminHidden {
    background-color: rgb(238, 255, 232);
}

.UserTableRowUserHidden {
    background-color: rgb(255, 232, 232);
}

.UserTableRowAdminHiddenP, .UserTableRowUserHiddenP {
    position: absolute;
    bottom: 3px;
    color: midnightblue;
    left: 3px;
    font-family: monospace !important;
    font-size: 12px;
}

.UserTableOptionAlien > p {
    font-family: monospace !important;
}

.UserTableOptionIcon {
    font-size: 16px !important;
    color: var(--tones-dark-grey);
    margin-left: 15px !important;
}

.UserTableOptionLoading {
    margin-left: 15px !important;
}

.UserTableRowIdCellNickname {
    max-width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #UserTableSearchSwitch:hover {
        border-color: var(--blues-main);
   }

   #UserTableSearchSwitch:hover #UserTableSearchSwitchIcon {
       color: var(--blues-main);
       transform: rotateY(180deg) rotate(-90deg);
   }

    #UserTableSearchModeCancel:hover {
        color: var(--warning-main);
    }

    .UserTableMainHeaderIcon:hover {
        color: var(blues-main);
    }

    #UserTableSortStarIcon:hover {
        color: gold;
    }

    .UserTableSortStarIconFulfilled:hover {
        color: black !important;
    }


    .UserTableRow:not(.UserTableRowIsSkeleton):hover {
        background-color: var(--blues-light);
    }

    .UserTableRow:not(.UserTableRowIsSkeleton):hover .UserTableRowIdCell {
        color: var(--blues-main) !important;
    }

    .UserTableRowOptionsIcon:hover {
        color: var(--blues-main);
    }

    .UserTableSettingsIcon:hover {
        color: var(--blues-main);
    }

    .UserTableOption:hover {
        background-color: var(--tones-off-white);
    }

    .UserTableOption:hover > p:first-child, .UserTableOption:hover > .UserTableOptionIcon {
        color: var(--blues-main);
    }

    .UserTableOption:hover > .UserTableOptionIcon {
        transform: translateX(-2px);
    }

    #UserTableNicknameModalClose:hover {
        color: var(--tones-blues-main);
    }

    #UserTableNickname > div > button:hover {
        filter: var(--filter-brightness);
    }
}