.NoAddressButton {
    width: 100%;
    background-color: var(--tones-white);
    box-sizing: border-box;
    border: var(--border-main);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 85px;
    transition: background-color 0.3s;
    user-select: none;
    position: relative;
}

.NoAddressWarning {
    background-color: var(--warning-light);
}

.EmptyWarningIconWrap {
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 3px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning-main);
    transition: opacity 0.3s;
}

.EmptyWarningIcon {
    color: var(--tones-white);
    font-size: 16px !important;
}

.EmptyWarningIconWrap {
    opacity: 0;
}

.AddAddressIcon {
    margin-right: 10px;
}

.EditAddressWrapAccountInfo {
    height: unset;
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.EditAddressWrap {
    position: relative;
    background-color: var(--tones-white);
    box-sizing: border-box;
    border: var(--border-main);
    border-radius: 3px;
    padding: 20px;
    width: 100%;
}

.EditAddressWrap > p:first-of-type {
    margin-bottom: 20px;
}

.EditAddressClose {
    color: var(--tones-dark-grey);
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
}

.DoubleAddressInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AddressCopyOverWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
}

.AddressCopyOverWrap > input {
    margin-right: 10px;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.AddressCopyOverWrap > input:checked {
    accent-color: var(--blues-main);
    color: var(--tones-white);
}

.AddressCopyOverWrap > p {
    cursor: pointer;
    user-select: none;
}

.AddressEndButtonWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.AddressEndButtonWrap > button {
    border: var(--border-blue);
    outline: none;
    padding: 15px;
    width: calc(50% - 10px);
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
}

.AddressCancelButton {
    background-color: var(--tones-white);
    color: var(--tones-black);

    transition: background-color 0.3s;
}

.AddressApplyButton {
    background-color: var(--blues-dark);
    color: var(--tones-white);
    transition: filter 0.3s;
}

.AddressInfoCollated {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--tones-dark-grey);
}

.AddressInfoCollated > p {
    width: 100%;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.AddressWrap {
    padding: 20px 20px 30px 20px;
    background-color: var(--tones-white);
    position: relative;
    border: var(--border-main);
    box-sizing: border-box;
    border-radius: 3px;
    min-height: 250px;
}

.AddressWrap > h4 {
    margin-bottom: 10px
}

.AddressWrapConf:last-of-type {
    margin-top: 40px;
}

.ExtraLineHeight > p {
    margin-bottom: 6px;
}

.EditExistingAddress {
    position: absolute;
    user-select: none;
    bottom: 12px;
    right: 12px;
    color: var(--blues-main);
    cursor: pointer;
    transition: filter 0.3s;
}

.AddressMainWarning {
    color: var(--warning-main);
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .NoAddressButton:hover {
        background-color: var(--blues-light);
    }

    .EditAddressClose:hover {
        color: var(--blues-main);
    }

    .AddressCancelButton:hover {
        background-color: var(--blues-light);
    }

    .AddressApplyButton:hover {
        filter: var(--filter-brightness);
    }

    .EditExistingAddress:hover {
        filter: var(--filter-brightness);
    }
}