.QuotePartSkeletonRoot {
    border-bottom: var(--border-main);
    border-left: var(--border-main);
    border-right: var(--border-main);
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background-color: var(--tones-white);
    opacity: 1;
}

.QuotePartSkeletonLastPart {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.QuotePartSkeleton {
    position: relative;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}

.QuotePartSkeletonIndex {
    background-color: var(--blues-light);
    border-radius: 2px;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.QuotePartSkeletonLoadingWrap {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 11;
    background-color: var(--blues-trans);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.QuotePartSkeletonLoadingWrap > div:not(.QuotePartSkeletonLoadingStatus) {
    position: absolute;
    height: 3px;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    background-color: var(--blues-light);
}

.QuotePartSkeletonLoadingBarConverting {
    background-color: var(--warning-light-converting) !important;
}

@keyframes skeletonConverting {
    from {transform: translateX(-110%)}
    to {transform: translateX(110%)}
}

.QuotePartSkeletonLoadingBarConverting > div {
    background-color: var(--warning-converting);
    z-index: 1;
    height: 100%;
    width: 100%;
    transition: background-color 0.4s;
}

.QuotePartSkeletonLoadingStatus {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 10px;
    font-weight: 600;
    background-color: var(--blues-light);
    padding: 2px 26px 5px 4px;
    border-top-right-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 90%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.4s;
}

.QuotePartSkeletonLoadingStatus > p:last-child {
    position: absolute;
    right: 0;
    width: 25px;
    height: 100%;
    padding-left: 5px;
    box-sizing: border-box;
    background-color: inherit;
    display: flex;
    align-items: center;
}

.QuotePartSkeletonLoadingStatus > p:last-child::after {
    content: ' .';
    animation: dots1 1s steps(5, end) infinite;
}

.QuotePartSkeletonStatusConverting {
    background-color: var(--warning-light-converting);
}

.SkeletonThumbnail {
    margin-left: 20px;
    flex: 1;
    min-width: 130px;
}

.SkeletonInfoWrap {
    margin-left: 20px;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 2;
}

.SkeletonCloneDeleteApply {
    margin-top: 5px !important;
}

.SkeletonSetButtonWrap {
    display: flex;
    height: 65%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 3;
}

.SkeletonsQuantityPriceWrap {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
    min-width: 400px;
    height: 100%;
}

.SkeletonsQuantityWrap {
    flex: 0.7;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.SkeletonQuantityInput {
    min-width: 100px;
}

.SkeletonQuantityText {
    margin-bottom: 7px !important;
}

.SkeletonQuantityBulkText {
    margin-top: 5px !important;
}

.SkeletonsPriceWrap {
    height: 70%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.SkeletonPriceElem {
    min-width: 160px;
}