#App {
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    width: 100vw;
    max-width: 100%;
    background-color: var(--tones-off-white);
}

#LoadingWrap {
    position: fixed;
    top: 0;
    left: 0;
    user-select: none;
    z-index: 9999999;
    background-color: var(--blues-trans);
    display: flex;
    align-items: center;
    justify-content: center;
}

#TopThemeBar {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    pointer-events: none;
}

.HideBannerOverflow {
    height: 60px !important;
    position: sticky !important;
    top: 0 !important;
    z-index: 14 !important;
}

#MainWrap {
    min-height: calc((var(--vh, 1vh) * 100) - 60px);
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#DevBreakPointNote {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    height: 20px;
    align-items: center;
    font-family: monospace !important;
    justify-content: center;
    white-space: nowrap;
    pointer-events: none;
    text-shadow: 0 0 3px darkmagenta;
    letter-spacing: 1px;
    background-color: rgba(124, 252, 0, 0.293);
    font-size: 10px;
    border-top-right-radius: 8px;
    box-sizing: border-box;
    padding: 10px;
    z-index: 9999999999999999;
    opacity: 1;
}

#DevBuildNote {
    position: fixed;
    bottom: 20px;
    left: 0;
    border-top-right-radius: 8px;
    z-index: 9999999999999999;
    background-color: rgba(124, 252, 0, 0.293);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    perspective: 120px;
    perspective-origin: center;
    pointer-events: none;
    opacity: 1;
}

@keyframes dev_note {
    0% {transform: rotateY(0deg)}
    50% {transform: rotateY(180deg)}
    100% {transform: rotateY(360deg)}
}

#DevBuildNote > p {
    font-family: copperplate monospace;
    font-size: 15px;
    color: Indigo;
    text-shadow: 0 0 3px darkmagenta;
    letter-spacing: 1px;
    padding: 0 5px;
    -webkit-user-select: none;
    user-select: none;
    animation: dev_note 10s linear 0s infinite;
}

#Announcement {
    position: relative;
    background-color: var(--tones-black);
    padding: 15px 40px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
}

#Announcement > div {
    width: 100%;
}

#Announcement > div > p {
    color: var(--tones-white);
    margin: 0;
    font-size: 14px;
    text-align: center;
}

#CloseAnnouncement {
    color: var(--tones-white);
    cursor: pointer;
    position: absolute;
    font-size: 18px;
    top: 10px;
    right: 10px;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */

.banner-wrapper {
    display: flex;
    position:relative;
    width: 100%;
    height: 320px;
    align-items: center;
    justify-content: center;
}

.banner-overflow {
    height: 60px !important;
    overflow: hidden;
}

.mackerel-banner {
    background:linear-gradient(90deg, #005ffe 10%, #2650fc 21.43%, #3a3ff9 32.86%, #4928f5 44.29%, #551ae0 55.71%, #5819c2 67.14%, #521691 95%);
    width: 100%;
    min-height: 320px;
    height: 320px;
    clip-path: ellipse(150% 100% at 50% -25%);
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
}

.mackerel-banner:before {
    background-image: url("./mackerel-banner-img.jpg");
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    background-repeat: repeat-x;
    background-size: auto 320px;
}

@keyframes loadingDots {
    0% {
        content: 'loading\00a0\00a0\00a0'; /* Three non-breaking spaces */
    }
    33% {
        content: 'loading.\00a0\00a0'; /* Two non-breaking spaces */
    }
    66% {
        content: 'loading..\00a0'; /* One non-breaking space */
    }
    100% {
        content: 'loading...'; /* No spaces needed */
    }
}

.loading-dots::after {
    content: 'loading&nbsp;&nbsp;&nbsp;';
    animation: loadingDots 1.5s infinite;
}