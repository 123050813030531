#FooterImgLinks {
    padding: 0 0 30px 0;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tones-off-white);
    box-sizing: border-box;
    justify-self: flex-end;
    margin-bottom: 20px;
}

#FooterImgLinksSectionWrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, max-content));
    grid-gap: 30px;
    justify-content: center;
    padding: initial;
}

.FooterImgLinksSection {
    position: relative;
    height: 230px;
    width: 380px;
    padding: 20px 20px 30px 20px;
    box-sizing: border-box;
    background-color: #E8F1FF;
    border-radius: 3px;
    color: var(--blues-main);
}

.FooterImgLinksSection:last-child {
    margin-right: 0;
}

.FooterImgLinksSection > img {
    height: 100%;
    border-radius: 3px;
}

.FooterImgLinksSection > div {
    height: 100%;
    width: 100%;
    background-color: var(--tones-white);
    /*  display background img centered and covered no stretch  */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    transition: filter 0.3s;
}

.FooterImgLinksSection > p {
    position: absolute;
    bottom: 8px;
    cursor: pointer;
    left: 20px;
    text-decoration: none;
    color: var(--blues-main);
}

/* media rule for hover only on devices that can */
@media (hover: hover) {
    .FooterImgLinksSection:hover > div {
        filter: var(--filter-brightness);
    }
}