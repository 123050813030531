@media screen and (max-width: 768px) {
    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* general */

    .PageContent {
        position: relative;
        top: 1px;
    }

    .mackerel-banner {
        background: linear-gradient(350deg, #005FFE, #521691);
    }

    .mackerel-banner::before {
        display: none;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* header */

    #HeaderInner {
        padding: 0 20px 10px 20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* footer & technology explanation */

    #Footer, #FooterSectionsWrap, #TechnologyExplanation {
        height: auto;
    }

    #FooterSections, #TechnologyExplanationSectionWrap {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: unset;
        align-items: unset;
    }

    #FooterSectionsWrap, #TechnologyExplanation {
        padding: 20px
    }

    .FooterSection p {
        font-size: 12px;
    }

    #TechnologyExplanationSectionWrap {
        padding: 0;
    }

    .FooterSection, .TechnologyExplanationSection {
        width: 256px;
        min-width: 256px;
        vertical-align: top;
        margin-right: 20px;
        margin-bottom: 20px;
        padding-right: 0;
    }

    .FooterSection:last-of-type, #TechnologyExplanationSection:last-of-type {
        margin-bottom: 0;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* authenticator */

    #UserModal {
        animation: none;
    }

    #UserModalContent {
        width: calc(100% - 3%);
        padding: 30px 20px;
    }

    #UserModalContent > form > button, #UserModalRButton {
        font-size: 14px !important;
    }

    #UserInfoModalClose {
        top: 10px;
        right: 10px;
        font-size: 20px;
    }

    #UserModalContent > p:first-of-type {
        font-size: 14px !important;
    }

    #ExtraBits h5 {
        margin-top: 5px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* headers */

    #UploadHeader, #ConfirmationHeader, #UserTableHeaderTitleWrap, #UserHeader {
        color: var(--tones-black);
        height: unset;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }

    #UploadHeader h1, #ConfirmationHeader h1, #UserTableHeaderTitleWrap h1, #UserHeader h1 {
        font-size: 25px !important;
        line-height: 28px;
        margin-bottom: 8px;
    }

    #UploadHeader h3, #ConfirmationHeader h3, #UserTableHeaderTitleWrap h3, #UserHeader h3 {
        font-size: 14px !important;
        line-height: 20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* uploads */

    #UploadHeader {
        background-color: #EFF4FB;
        box-sizing: border-box;
        color: var(--tones-black);
        min-width: 320px;
    }

    #SelectTechnologyWrap {
        margin-top: 10px
    }

    #SelectTechnologyWrap h3 {
        font-size: 14px !important;
    }

    #SelectTechnologySelect {
        padding: 0 10px 0 18px;
        min-width: 95px;
    }

    #SelectTechnologyLeft {
        flex: 4;
    }

    #SelectTechnologyIcon {
        font-size: 25px;
        margin-left: 10px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* upload drop zone */

    #UploadPartsHeader h3 {
        font-size: 14px !important;
    }

    #UploadDropZoneChild {
        min-height: 300px;
    }

    #UploadPartsWrapChild {
        margin-top: 10px;
        min-height: 300px;
        margin-bottom: 10px;
    }

    #UploadButtonLabel {
        margin: 0 0 20px;
    }

    #UploadButton > p {
        font-size: 14px !important;
    }

    #DropzoneChildContinueButton {
        position: unset;
        width: 100%;
        font-size: 14px !important;
        margin-top: 0;
    }

    #DropzoneFullscreenContinueButton {
        font-size: 14px;
        width: 100%;
    }

    #UploadButton2 {
        padding: 12px 25px;
    }

    #AddMorePartsButton {
        margin-right: 0;
    }

    #NoPartsDiv > p {
        font-size: 12px !important;
        text-align: center;
        padding: 0 20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* uploading part */

    .UploadingFile > p {
        flex: 1;
    }

    .ProgressWrap {
        margin-right: 20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user info modal */

    #UserInfoInputWrap {
        width: calc(100% - 3%);
        padding: 30px 20px;
    }

    #UserInfoInputWrap h3 {
        font-size: 14px !important;
    }

    .Wanna {
        margin-top: 5px;
    }

    #UserInfoInputWrap > form > button, #UserInfoButtonLoggedIn, #AdminEmail, #UserInfoInputWrap > p {
        font-size: 14px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* alien */

    #AlienWrap {
        padding: 7px 15px 7px 7px;
    }

    #AlienButtonWrap {
        margin: 0;
    }

    #AlienButtonWrap > p {
        font-size: 40px;
    }

    #AlienTerminal {
        flex: 1;
        padding: 5px 20px 5px 5px;
        min-width: unset;
    }

    #AlienTerminal:after {
        bottom: 11px;
    }

    #AlienTerminal > p {
        font-size: 10px;
    }

    #AlienActionsWrap {
        right: 18px;
        bottom: 70px;
    }

    .AlienActionWrap {
        margin-top: 18px;
    }

    .AlienActionLabel {
        right: 45px;
        font-size: 10px;
    }

    .AlienAction {
        font-size: 12px;
    }

    #AlienIntermediateWrap > div {
        width: calc(100% - 3%) !important;
    }

    #AlienAssuming {
        box-shadow: inset 0 0 15px lime;
    }

    #AlienAssuming > div, #AlienWholeWrap > div {
        min-width: 160px;
    }

    #AlienAssuming > div > div {
        padding: 10px;
    }

    #AlienAssuming > div > div > p {
        font-size: 10px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote */

    #PartsHeaderStartWrap h3 {
        font-size: 14px !important;
    }

    #QuoteToolbar, #QuoteSkeletonToolbarWrap {
        min-height: 55px;
        margin-bottom: 0;
    }

    #QuoteSkeletonToolbarWrap {
        margin-bottom: 15px;
    }

    #ShowDropZoneButton {
        padding: 12px 30px;
    }

    #ShowDropZoneIcon {
        margin-right: 0;
    }

    #QuoteBottomWrap {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    #QuoteBottomStart {
        margin-bottom: 26px;
        margin-right: 0;
    }

    .SelectedOptionIndicatorWrap {
        margin-left: 10px;
    }

    .PriceBreakdownElem, .ShippingRateOptionWrap, .LeadTimeOptionWrap {
        padding: 17px 10px;
    }

    .LeadTimeOptionWrap {
        padding: 10px;
    }

    .PriceBreakdownElem h4, .PriceBreakdownElem:not(:last-of-type) p {
        font-size: 14px !important;
    }

    .CheckoutButton {
        font-size: 14px;
    }

    #QuoteOverlayWarningModalOptionsWrap {
        width: 100%;
    }

    #QuoteOverlayWarningModalWrap > div {
        width: calc(100% - 3%);
        padding: 30px 20px;
    }

    #QuoteOverlayWarningModalCloseButton, #QuoteDismissibleNoteModalCloseButton {
        font-size: 20px;
    }

    #QuoteOverlayWarningModalWrap > div > p {
        font-size: 14px;
    }

    #QuoteOverlayWarningModalTechMatWrap > p, #QuoteOverlayWarningModalTechMatWrap > p > span, #QuoteOverlayWarningModalQuantityWrap {
        font-size: 14px !important;
        white-space: nowrap;
    }

    #QuoteOverlayWarningModalQuantityWrap > p {
        font-size: 14px;
    }

    #QuoteOverlayWarningModalWrap > div > div > button, #QuoteDismissibleNoteModalButtonWrap > button {
        font-size: 14px;
    }

    #QuoteOverlayWarningModalWrap > div > p:last-of-type {
        width: unset;
    }

    #QuoteOverlayWarningModalWrap > div > div:last-of-type {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #ExpiredQuoteMemo  {
        flex-direction: column;
        align-items: flex-start;
    }

    #ExpiredQuoteMemo > p, #ExpiredQuoteMemo > p > span {
        font-size: 14px;
    }

    #ExpiredCopyButton {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
        font-size: 14px;
    }

    .QuoteWarning > p {
        margin-left: 0;
    }

    #QuoteBottomStart {
        width: 100%;
    }

    .QuoteBottomSection, #QuoteBottomStart > .QuoteBottomSection, #QuoteSkeletonBottom > span {
        width: 100%;
        min-width: 100%;
    }

    #QuoteBottomSection {
        margin-bottom: 10px;
    }

    #QuoteWarning > #QuoteWarningIconWrap {
        width: 25px;
        height: 25px;
    }

    #QuoteBottomWrap {
        margin-bottom: 15px;
    }

    #QuoteOverlayWarningModalQuantityWrap > p {
        margin-left: 8px;
    }

    .PriceBreakdownElemMaxWidth {
        max-width: 250px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote part */

    .QuotePart, .QuotePartSkeleton {
        height: unset;
        display: block;
    }

    .QuotePartThumbInfo, .SkeletonIndexThumbnailInfo, .SkeletonsDoublePriceWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px 15px;
    }

    .SkeletonIndexThumbnail {
        margin-left: 0;
    }

    .PartThumbnailWrap {
        margin: 0;
        height: 110px;
        width: 110px;
        min-width: 110px;
        flex: unset;
        border: var(--border-light)
    }

    .SkeletonThumbnail {
        min-width: 110px;
    }

    .PartInfoWrap, .SkeletonInfoWrap {
        align-items: flex-end;
        text-align: end;
        height: unset;
    }

    .PartInfoWrap > h3 {
        font-size: 14px !important;
        max-width: 260px;
    }

    .CloneDeleteP {
        margin-bottom: 5px;
    }

    .PartViewerIconSmall {
        position: absolute;
        bottom: 1px;
        right: 1px;
        font-size: 15px;
        color: var(--tones-grey);
        opacity: 0.8;
    }

    .TechnologyFinishWrap, .SkeletonSetButtonWrap {
        box-sizing: border-box;
        padding: 10px 15px 10px 15px;
    }

    .SkeletonSetButtonWrap > span:not(:last-of-type) {
        height: 110px !important;
        margin-bottom: 20px !important;
    }

    .SkeletonSetButtonWrap > span {
        height: 42px !important;
    }

    .SkeletonsPriceWrap {
        margin-right: 0;
    }

    .SkeletonsPriceWrap:last-of-type {
        align-items: flex-end;
    }

    .SkeletonsPriceWrap > span:not(:last-of-type) {
        margin-bottom: 10px;
    }

    .QuantityPriceWrap {
        flex: unset;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 15px;
        min-width: unset;
        height: unset;
    }

    .PartPriceWrap {
        height: unset;
        display: block;
        width: 100%;
        margin: 0 10px 0 0;
    }

    .PartPriceWrap > div {
        width: 100%;
        margin-bottom: 10px;
    }

    .PartPriceWrap > div:last-of-type {
        margin-bottom: 0;
    }

    .SetButtonFinishOptionHex {
        width: 15px;
        height: 15px;
    }

    .TechnologyFinishWrapRoot {
        height: 110px;
        width: 100%;
        max-width: none;
        min-width: initial;
    }

    .FlexedQuantityInputWrap {
        margin-top: 20px;
        width: 100%;
    }

    .FlexedQuantityInputWrap > div {
        width: 100%;
    }

    .QuotePartCollapsable__root {
        margin-top: 10px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quantity input */

    .QuantityInputFlexed > .Quantity {
        height: 42px;
        min-height: 42px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --/ */
    /* address handler */

    .AddressWrap, .EditAddressWrap {
        padding: 15px 15px 30px 15px;
    }

    .AddressInfoCollated > p, .AddressCopyOverWrap > p, .AddressEndButtonWrap > button {
        font-size: 14px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* checkout */

    .CheckoutHalf > div {
        width: calc(100% - 3%);
    }

    #CheckoutButton, #POApplyButton {
        width: 100%;
        font-size: 14px !important;
    }

    #CheckoutPOInputWrap {
        padding: 15px;
    }

    .PaddedFormElem {
        padding: 15px 15px 0 15px;
    }

    #POInputs, #UploadPO, #POSubmit {
        padding: 0 15px;
    }

    .CheckoutOptionTab {
        padding: 0 15px;
    }

    .CheckoutSummaryElem {
        padding: 0 15px;
    }

    #POApplyWrap {
        padding: 15px;
    }

    #POApplyWrap > * {
        font-size: 14px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* confirmation */

    #ConfirmationHeader {
        background-color: #EFF4FB;
        box-sizing: border-box;
        color: var(--tones-black);
        min-width: 320px;
    }

    #ConfirmationHeaderBottom {
        display: initial;
        margin-top: 10px;
        margin-bottom: 0;
        width: unset;
    }

    #ConfirmationMain {
        margin-bottom: 15px;
    }

    #ConfirmationHeaderBottom h4 {
        margin-bottom: 2px;
    }

    #ConfirmationHeaderBottom p:not(:last-child) {
        margin-bottom: 8px;
    }

    #ConfirmationHiding > div {
        width: calc(100% - 3%);
        margin: 3% 0;
        min-height: calc(100% - 3%);
    }

    #ConfirmationHiding > div > div > h2 {
        font-size: 24px !important;
    }

    #ConfirmationHiding > div > div > p {
        font-size: 14px !important;
    }

    #UserInformationWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    #UserInformationWrap > div, .UserAddressSkeleton {
        width: 100%;
        margin-top: 26px !important;
    }

    #UserInformationWrap > div:not(:last-child), .UserAddressSkeleton:not(:last-child) {
        margin-right: 0;
    }

    #FetchInvoice {
        margin-right: 0;
        color: var(--blues-main);
        text-decoration: none !important;
    }

    #FetchInvoiceCircular {
        left: -20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user */

    #UserHeader {
        background-color: #EFF4FB;
        box-sizing: border-box;
        color: var(--tones-black);
        min-width: 320px;
    }

    .UserSectionMarginTop  {
        margin-top: 10px;
    }

    .UserInfoInputWrap, .UserInfoMultiInputWrap {
        width: 100%;
    }

    .UserInfoElem {
        flex-direction: column;
    }

    .UserInfoElem > h4 {
        margin-bottom: 7px;
    }

    .UserInfoElem > * {
        flex: unset !important;
    }

    .UserInfoElem > div:not(.UserInfoMulti) {
        margin-bottom: 7px;
    }

    .UserSectionButtonWrap {
        position: initial;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        justify-content: flex-end;
    }

    .UserSectionButtonWrap > button:first-of-type {
        margin-left: 0;
    }

    #DeleteUserWrap {
        margin-bottom: 40px;
    }

    #DeleteUserWrap > button {
        width: 100%;
    }

    #UserDeleteAreYouSureModal {
        width: calc(100% - 3%);
        padding: 35px 20px;
    }

    .UserChangeCurrencyRoot {
        width: 100%;
    }

    .CurrencyEditWrap {
        width: 100%;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user table */

    #UserTableHeader {
        background-color: #EFF4FB;
        box-sizing: border-box;
        color: var(--tones-black);
        min-width: 320px;
        flex-direction: column;
    }

    #UserTableHeaderTitleWrap {
        padding: 20px 20px 15px 20px;
    }

    #UserTableMainHeadersWrap, .UserTableRow {
        padding: 0 5px;
    }

    #UserTableSearchWrap {
        margin: 0 20px 20px 0;
    }

    #UserTableMain {
        margin-top: 10px;
    }

    .UserTableRowSettingsIcon {
        font-size: 18px !important;
    }

    .UserTableMainHeader:last-of-type > h3 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .UserTableRowCell, .UserTableMainHeader {
        padding: 0 5px;
    }

    #UserTableSortStarIcon {
        margin-left: 8px;
        font-size: 16px !important;
    }

    .UserTableIconWrap {
        top: 2px;
        left: 2px;
    }

    .UserTableRowIcon {
        font-size: 14px !important;
        margin-right: 1px;
    }

    #UserTableMain {
        margin-bottom: 15px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* challenge */

    #ChallengeContent, #LoginContent {
        width: calc(100% - 3%);
        padding: 20px;
    }

    #ChallengeClose {
        top: 15px;
        right: 15px;
        font-size: 20px;
    }

    #ChallengeContent > form, #LoginContent > form  {
        margin-bottom: 0;
    }


    #ChallengeContent > form > button, #ChallengeLogout, #ChallengeEmail, #LoginContent > form > button {
        font-size: 14px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* request modal */

    #RequestQuote {
        padding: 14px;
    }

    #RequestQuote:not(.RequestQuoteAuthenticator) {
        height: calc(100% - 3%);
        width: calc(100% - 3%);
        min-width: unset;
    }

    .RequestQuoteAuthenticator {
        width: calc(100% - 3%);
    }

    #RequestFormTextAreaWrap {
        min-width: unset;
        min-height: unset;
        flex: 1;
        width: 100%;
    }

    #RequestFormTextAreaWrap textarea {
        padding: 5px;
        min-width: unset;
        min-height: unset;
        height: 100% !important;
        width: 100% !important;
        resize: none;
        font-size: 12px !important;
    }

    #RequestQuote > p, #RequestQuote > form > .Bold16, .Reg16 {
        font-size: 13px !important;
        line-height: unset;
    }

    #RequestQuoteUserInfo {
        font-size: 12px !important;
    }

    .RFQMarginBottom {
        margin-bottom: 10px !important;
    }

    #RFQAttachButtonWrap {
        flex-direction: column;
        align-items: flex-start;
        font-size: 10px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* under review */

    #UnderReview {
        padding: 20px;
    }

    #UnderReview:not(.UnderReviewAuthenticator), .UnderReviewAuthenticator {
        width: calc(100% - 3%);
    }

    #UnderReview .Reg16, .Bold16 {
        font-size: 14px !important;
    }

    #UnderReviewButtonWrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    #UnderReviewButtonWrap > button {
        font-size: 14px;
        margin-bottom: 10px !important;
    }

    #UnderReviewButtonWrap > p {
        margin-bottom: 20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* bits */

    #PrivacyPolicy {
        box-sizing: border-box;
        margin-top: 45px;
        padding: 0 20px;
    }

    #PrivacyPolicyWrap {
        width: 256px;
        height: 120px;
        min-width: 256px;
    }

    #PrivacyPolicyWrap > div {
        flex-direction: column-reverse;
    }

    #PrivacyPolicyWrap > h6 {
        padding: 0 20px;
    }

    #PrivacyIcon {
        margin-right: 0;
        margin-top: 5px;
    }

    #PrivacyPolicyWrap > h4, #PrivacyPolicyWrap > h4 > a {
        font-size: 12px;
    }

    #PartViewerLoadingP {
        font-size: 12px !important;
    }

    #WantToRegisterModal, #UserTableNickname {
        width: calc(100% - 3%) !important;
        padding: 35px 20px !important;
    }

    #DismissibleNoteModalWrap > div {
        width: calc(100% - 3%);
        padding: 30px 20px;
    }

    #DismissibleNoteModalWrap > div > p {
        font-size: 14px;
    }

    #PartViewer > div, #UploadPartsWrapFullScreen {
        max-height: unset;
    }

    .PartViewerConvertedWarning {
        width: fit-content;
        height: unset;
        position: relative;
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 5px 5px 5px 20px;
    }

    .PartViewerConvertedWarning > p {
        font-size: 11px !important;
        white-space: unset;
    }

    .PartViewerConvertedWarningIconWrap {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
    }

    #CookieNoticeButtonWrap > button {
        padding: 8px 12px;
    }

    #CookieNoticeManage > div {
        margin-top: 10px;
    }

    #CookieNoticeManage > div:last-of-type {
        margin-bottom: 10px;
    }
}