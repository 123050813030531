#Login {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00112e;
    z-index: 10;
}

#LoginContentWrap {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#LoginLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blues-trans);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

#LoginContent {
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    width: 500px;
    background-color: var(--tones-white);
    padding: 30px;
}

#LoginContent > form {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    margin: 10px 0;
}

#LoginContent > form > input, #LoginContent > form > button {
    padding: 0;
    border: none;
    border-radius: 3px;
    outline: none;
}

#LoginContent > form > input {
    height: 40px;
    border: var(--border-main);
    padding: 0 20px;
    width: calc(100% - 42px);
    background-color: var(--blues-light);
    margin-bottom: 10px;
}

#LoginContent > form > input:focus {
    border: var(--border-blue);
}

#LoginContent > form > div {
    color: var(--warning-main);
    align-self: flex-start;
}

#LoginContent > form > button {
    border-radius: 3px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    width: 100%;
    border: none;
    outline: none;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 10px;
}

@media (hover: hover) {
    #LoginContent > form > button:hover {
        background-color: var(--blues-dark);
    }
}