.OptionsListWrap {
    background-color: var(--tones-white);
    border-radius: 3px;
    overflow: hidden;
}

.OptionsListTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    box-sizing: border-box;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: var(--border-main);
    border-bottom: none;
}

.OptionsListTitle > h4 {
    color: var(--tones-dark-grey);
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    margin-right: 15px;
}

.OptionsLoading {
    border-bottom: none !important;
}

.OptionsLoading:after {
    content: ' .';
    animation: dots2 1.3s steps(5, end) infinite;
}

.OptionsListOption {
    border: var(--border-main);
    border-bottom: none;
    background-color: var(--tones-white);
    transition: background-color 0.3s;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.OptionsListOption:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom: var(--border-main);
}

.OptionSelected {
    pointer-events: none;
    border-color: var(--blues-main) !important;
}

.OptionSelected + .OptionsListOption {
    border-top-color: var(--blues-main) !important;
}

.OptionSelected {
    background-color: var(--blues-light);
}

.OptionSelected *[hoverhighlight="true"] {
    color: var(--blues-main) !important;
}

.SelectedOptionIndicatorWrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

.SelectedOptionIndicator {
    color: var(--blues-main);
    font-size: 15px !important;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .OptionsListOption:hover {
        background-color: var(--blues-light);
    }

    .OptionsListOption:hover *[hoverhighlight="true"] {
        color: var(--blues-main) !important;
    }
}