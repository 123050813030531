.ImgCarouselFullscreen {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
}

.ImgCarousel {
    height: 100%;
    width: 100%;
    background-color: var(--tones-light-grey);
    user-select: none;
}

.ImgCarouselTop {
    height: 60px;
    border-bottom: var(--border-main);
    box-sizing: border-box;
    cursor: initial;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ImgCarouselFullscreen > .ImgCarousel {
    border: 1px solid var(--tones-white);
    max-width: 90%;
    max-height: 90%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--blues-light);
    width: unset;
}

.ImgCarouselFullscreen .ImgCarouselArrowsWrap {
    top: 60px;
    height: calc(100% - 60px);
}

.ImgCarouselFullscreen .ImgCarousel img {
    height: calc(100% - 60px);
    max-width: 100%;
    max-height: 100%;
    background-color: var(--blues-mid);
    object-fit: contain;
}

/* every image inside of carousel should fit un-stretched into the height and width of .ImgCarousel */
.ImgCarousel img {
    height: 100%;
    width: 100%;
    /* img settings to full fill parent without stretching and to be centered */
    object-fit: cover;
    position: relative;
    vertical-align: middle;
}

.ImgCarouselImgCaption {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    box-sizing: border-box;
    /* color and text-shadow to make text visible on any image */
    color: var(--tones-white);
    text-shadow: 0 0 2px var(--tones-black);
}

.ImgCarouselArrowsWrap {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    box-sizing: border-box;
    pointer-events: none;
    padding: 0;
}

.ImgCarouselArrow {
    cursor: pointer;
    pointer-events: all;
    transform: scale(1);
    transition: transform 0.3s;
}

.ImgCarouselDots {
    align-self: flex-end;
    display: flex;
    height: 20px;
    align-items: center;
    margin-bottom: 10px;
}

.ImgCarouselDot {
    background-color: #f6f6f6;
    height: 7px;
    width: 7px;
    flex: 1;
    border-radius: 50%;
    box-shadow: 0 0 2px var(--tones-black);
    transform: scale(1);
    transition: transform 0.3s, color 0.3s;
    pointer-events: all;
    cursor: pointer;
}

.ImgCarouselDot:not(:last-child) {
    margin-right: 12px;
}

.ImgCarouselDotSelected {
    transform: scale(1.4);
    background-color: var(--tones-white);
    cursor: initial;
}

.ImgCarouselExpandIcon {
    position: absolute;
    top: 10px;
    font-size: 20px !important;
    right: 10px;
    cursor: pointer;
    pointer-events: all;
    color: var(--tones-white);
    transform: scale(1);
    transition: transform 0.3s !important;
}

.ImgCarouselCloseIcon {
    transform: unset;
    transition: color 0.3s;
}

.ImgCarouselLoading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tones-light-grey);
}

.ImgCarouselInteractive {
    cursor: pointer;
}

.ImgCarouselFullscreen .ImgCarouselImgCaption {
    color: var(--tones-black);
    text-shadow: unset;
    font-weight: 500;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    width: unset;
    padding: unset;
}

.ImgCarouselTop > div {
    box-sizing: border-box;
    width: 70px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.ImgCarouselCloseIcon {
    color: var(--tones-dark-grey) !important;
    font-size: 22px !important;
    transition: color 0.3s;
}

.ImgCarouselArrowWrap {
    height: 50%;
    width: 20%;
    display: flex;
    align-items: center;
    pointer-events: all;
    cursor: pointer;
    padding: 0 10px;
}
.ImgCarouselFullscreen .ImgCarouselArrowWrap {
    width: 40%;
    height: 100%;
    padding: 0 20px;
}

.ImgCarouselFullscreen .ImgCarouselArrowWrap .ImgCarouselArrow {
    transform: scale(1.5);
}

.ImgCarouselArrowWrap:last-of-type {
    justify-content: flex-end;
}

.ImgCarousel404 {
    height: 100%;
    width: 100%;
    z-index: -1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: rgba(253, 85, 45, 1);
}

.ImgCarousel404 > p {
    box-sizing: border-box;
    padding: 5px;
    border-radius: 3px;
    color: var(--tones-dark-grey);
    margin: 0 0 7px 7px;
    background-color: var(--tones-light-grey);
}

/* hover media rule to prevent hover events happening on touchscreen devices */
@media (hover: hover) {
    .ImgCarouselArrowWrap:hover .ImgCarouselArrow {
        filter: var(--filter-brightness);
        transform: scale(1.2);
    }

    .ImgCarouselFullscreen .ImgCarouselArrowWrap:hover .ImgCarouselArrow {
        transform: scale(1.6);
        filter: var(--filter-brightness);
    }

    .ImgCarouselDot:not(.ImgCarouselDotSelected):hover {
        transform: scale(1.3);
    }

    .ImgCarouselInteractive:hover .ImgCarouselExpandIcon, .ImgCarouselExpandIcon:hover {
        transform: scale(1.2);
    }

    .ImgCarouselTop > div:hover {
        background-color: var(--blues-main);
    }

    .ImgCarouselTop > div:hover .ImgCarouselCloseIcon {
        color: var(--tones-white) !important;
    }
}