#CookieNoticeWrap {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
    pointer-events: none;
}

#CookieNotice {
    z-index: 10;
    position: absolute;
    box-sizing: border-box;
    padding: 30px;
    bottom: 0;
    left: 0;
    border-top: var(--border-main);
    width: 100%;
    background-color: var(--blues-light);
    color: var(--tones-dark-grey);
    pointer-events: all;
}

#CookieNotice > h2 {
    margin-bottom: 20px;
}

#CookieNotice > p > a {
    text-decoration: none;
    cursor: pointer;
    color: var(--blues-main);
    transition: filter 0.3s;
}

#CookieNoticeButtonWrap {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
}

#CookieNoticeButtonWrap > button {
    display: inline-block;
    border-radius: 3px;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 12px 25px;
    background-color: var(--blues-main);
    color: var(--tones-white);
    transition: background-color 0.3s, filter 0.3s;
    cursor: pointer;
}

#CookieNoticeButtonWrap > button:first-child {
    margin-right: 20px;
    background-color: var(--tones-white);
    border: var(--border-main);
    color: var(--tones-dark-grey);
}

#CookieNoticeManage {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    white-space: nowrap;
}

#CookieNoticeManage > div {
    display: flex;
    flex-direction: row-reverse;
}

#CookieNoticeManage > div > label {
    margin-right: 10px;
}

#CookieNoticeManage > div > input {
    cursor: pointer;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #CookieNotice > p > a:hover {
        filter: var(--filter-brightness);
    }

    #CookieNoticeButtonWrap > button:hover {
        filter: var(--filter-brightness);
    }
}