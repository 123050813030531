.SelectInputWrap > p:first-of-type {
    color: var(--tones-dark-grey);
}

.SelectInputWrap > p:nth-child(3) {
    margin-top: 2px;
}

.SelectInputWarning, .SelectInput[indicatewarning = 'true'] {
    border: var(--border-warning) !important;
}

.InputWrittenWarning {
    color: var(--warning-main);
    margin-bottom: 4px;
}

.SelectInputRoot {
    background-color: var(--tones-white);
    box-sizing: border-box !important;
    line-height: unset !important;
    width: 100% !important;
    outline: none !important;
    border: var(--border-main) !important;
    border-radius: 3px !important;
    white-space: nowrap;
    transition: border 0.3s;
    color: var(--tones-dark-grey) !important;
}

.SelectInputSelect {
    padding: 10px 15px !important;
    border-radius: 3px !important;
    outline: none !important;
    min-height: 45px !important;
    transition: border 0.3s;
    box-sizing: border-box !important;
    padding-right: 38px !important;
    display: flex !important;
    align-items: center;
}

.SelectInputSelect[aria-expanded="true"] {
    background-color: var(--tones-white) !important;
}

.SelectInputSelect:focus {
    background-color: var(--tones-white) !important;
}

.SelectInputIcon {
    color: var(--tones-dark-grey) !important;
    font-size: 20px !important;
    position: relative;
    right: 10px !important;
}

.SelectInputMenu {
    box-sizing: border-box !important;
    transform: translateY(5px) !important;
    border-radius: 3px !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.SelectInputMenu::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
}

.SelectInputList {
    border-radius: 3px !important;
    padding: 0 !important;
    max-height: 300px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.SelectInputList::-webkit-scrollbar {
    display: none !important;
}

.SelectMenuItem {
    min-height: 45px;
    padding: 10px 15px !important;
    background-color: var(--tones-white) !important;
}

.SelectMenuItemFocused {
    background-color: var(--blues-light) !important;
}

.SelectCountryWrap {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    color: var(--tones-dark-grey)
}

.SelectCountryWrap > p:first-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.SelectCountryFlag {
    font-size: 18px;
}

.SelectInputSelectWarning {
    border: var(--border-warning) !important;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .SelectMenuItem:hover {
        background-color: var(--blues-light) !important;
    }
}