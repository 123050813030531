@media screen and (max-width: 480px) {
    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* cookie notice */

    #CookieNotice {
        height: calc(var(--vh, 1vh) * 100);
        bottom: unset;
        right: unset;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    #CookieNotice h2 {
        font-size: 24px !important;
    }

    #CookieNotice *:not(h2) {
        font-size: 14px !important;
    }

    #CookieNotice > p {
        box-sizing: border-box;
        padding-right: 15px;
        max-height: calc(var(--vh, 1vh) * 60);
        overflow: auto;
    }

    #CookieNoticeButtonWrap > button {
        padding: 8px 12px;
    }

    #CookieNoticeButtonWrap {
        flex: 1;
        width: 100%;
        margin-top: 0;
        flex-direction: column;
        align-items: end;
    }

    #CookieNoticeButtonWrap > button:first-of-type {
        margin: unset;
    }

    #CookieNoticeButtonWrap > button:last-of-type {
        margin-top: 10px;
    }

    #CookieNoticeManage {
        align-items: flex-start;
    }

    #CookieNoticeManage > div {
        flex-direction: row;
    }

    #CookieNoticeManage > div > label {
        margin-right: 0px;
        margin-left: 10px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* alien */

    #AlienWrap {
        padding: 5px 12px 5px 5px;
    }

    #AlienTerminal > p {
        font-size: 9px;
    }

    #AlienActionsWrap {
        width: 30px;
    }

    #AlienActionsWrap {
        right: 17px;
    }

    .AlienActionWrap {
        height: 30px;
    }

    .AlienActionLabel {
        font-size: 8px;
        right: 35px;
        max-width: calc(100vw - 90px);
        width: max-content;
    }

    #AlienIntermediateWrap p, #AlienIntermediateCustomShip > input, #AlienIntermediateDismissibleNote > textarea {
        font-size: 10px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote */

    #PartsWrap {
        min-height: unset;
        margin-bottom: 0;
    }

    #QuoteToolBarEndElemMargin {
        margin: 0 !important;
    }

    .PriceBreakdownColourPreview {
        width: 20px;
        min-width: 20px;
        height: 15px;
        border-radius: 2px;
        box-sizing: border-box;
        margin-right: 10px;
    }

    .PriceBreakdownElemMaxWidth {
        max-width: 170px;
    }

    .TechnologyFinishInfo > div > p:first-of-type {
        min-width: 20%;
        width: 20%;
    }

    .TechnologyFinishInfo > div > p:nth-of-type(2), .TechnologyFinishInfo > div > div {
        max-width: 75%;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* part viewer */

     #PartViewerHeaderStartWrap > h3 {
        font-size: 14px !important;
    }

    #ClearMeasurementsWrap > p {
        font-size: 14px !important;
    }

    #PartViewerTopLeftInfo {
        top: 10px;
        left: 10px;
    }

    #PartViewerTopLeftInfo > p {
        font-size: 10px !important;
    }

    #PartViewerUtilsWrap {
        top: 10px;
        right: 10px;
    }

    .PartViewerUtils {
        min-width: 130px;
        margin-bottom: 10px;
    }

    .PartViewerUtilsHeader {
        padding: 6px 8px;
    }

    .PartViewerUtilsHeader > h4 {
        font-size: 10px !important;
    }

    .PartViewerUtilsHeader > svg {
        font-size: 15px !important;
    }

    .PartViewerUtilsAction {
        padding: 4px 12px;
    }

    .PartViewerUtilsAction > div {
        height: 22px;
        width: 18px;
    }
    .PartViewerUtilsAction > p {
        font-size: 10px !important;
    }

    .SectionAnalysisAxisWrap {
        padding: 0 12px;
    }

    .SectionAnalysisAxisWrap > div > p, .SectionAnalysisAxisWrap > p, .SectionAnalysisAxisInputWrap > input, .SectionAnalysisAxisInputWrap > p {
        font-size: 10px !important;
    }

    .SectionAnalysisAxisWrap > p, .SectionAnalysisAxisInputWrap {
        margin-left: 5px;
        width: 65px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote part */

    .QuotePartWarning {
        padding: 5px 5px 5px 27px;
    }

    .QuotePartWarningIconWrap {
        height: 20px;
        width: 20px;
    }

    .PartInfoWrap > h3 {
        max-width: 120px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* checkout */

    #CheckoutLinkToQuote, #CheckoutPOInputWrap > p, #CheckoutPOInputWrap > p > span {
        font-size: 14px;
    }

    .LinkBackIcon {
        font-size: 20px !important;
    }

    .CheckoutSummaryElem > h3, .CheckoutSummaryElem > p:nth-child(1), .CheckoutSummaryElem > .MainBlue {
        font-size: 14px;
    }

    .CheckoutSummaryElem > p {
        font-size: 12px;
    }

    .CheckoutOptionTab > p:last-of-type {
        font-size: 11px !important;
    }

    #POInputs > p {
        font-size: 14px;
    }

    #POUploadButton {
        font-size: 14px;
    }

    #CheckoutTerms > p {
        font-size: 10px;
    }

    .QuoteOverlayWarningModalCheckbox {
        margin-right: 10px !important;
    }

    .CheckoutSummaryElemColourPreview {
        width: 20px;
        min-width: 20px;
    }

    .CheckoutSummaryElemColourLabel > p {
        max-width: 165px;
    }

    .CheckoutUpgradeWrap {
        padding: 10px 15px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* confirmation */

    .ConfirmationSummarySection:first-of-type > div:first-of-type > h3 {
        font-size: 14px !important;
    }

    .ConfirmationSummarySection > div > p:last-of-type:not(.MainBlue) {
        font-size: 12px !important;
    }

    .ConfirmationSummarySection > div > .MainBlue, .ConfirmationSummarySection > div > p:first-of-type, .ConfirmationBreakdownElemColourLabel > p {
        font-size: 14px !important;
    }

    .ConfirmationSummarySection > div {
        padding: 0 15px;
    }

    #FetchInvoice {
        font-size: 12px !important;
    }

    .ConfirmationBreakdownElemColourPreview {
        min-width: 20px;
        width: 20px;
    }

    .ConfirmationBreakdownElemColourLabel > p {
        max-width: 165px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user */

    #UserDeleteAreYouSureModalClose {
        top: 10px !important;
        right: 10px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user table */

    .UserTableMainHeader > h3 {
        font-size: 12px !important;
    }

    .UserTableRowLink > p, .UserTableRowCell > p:first-of-type, .UserTableRowStatusCell > p:first-of-type {
        font-size: 11px;
    }

    .UserTableRowCell > p:nth-child(2), .UserTableRowStatusCell > p:nth-child(2), .UserTableRowLink > p:nth-child(2) {
        font-size: 9px;
    }

    #UserTableSettingsWrap {
        width: 100%;
        margin-bottom: 15px;
    }

    #UserTableSettingsWrap > p, #UserTableSettingsRPP > p {
        font-size: 10px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* challenge */

    #Challenge, #Login {
        position: initial;
        width: 100%;
    }

    #ChallengeContent, #LoginContent {
        margin: 10px 0;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* request modal */

    #RequestQuote > .Reg16, #RequestQuote > form > .Reg16 {
        font-size: 12px !important;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* bits */

    #WantToRegisterModalClose {
        top: 10px !important;
        right: 10px !important;
    }

    .AddressInfoCollated > p {
        max-width: 280px;
    }
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* final catch @ 320px width */

@media screen and (max-width: 320px) {
    #App, .FullHeightWidth {
        width: 320px !important;
    }

    .PageContent, .PageContentWidth {
        width: calc(320px - 4%);
    }
}
