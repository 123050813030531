.QuotePartCollapsable__root {
    box-sizing: border-box;
    padding: 20px 20px 20px 20px;
    border-top: var(--border-main);
}

.QuotePartCollapsable__tier {
    width: 100%;
}

.QuotePartCollapsable__tier > div:first-of-type {
    margin-top: 30px;
}

.QuotePartCollapsable__tier:first-of-type > div:first-of-type {
    margin-top: 10px;
}

.QuotePartCollapsable__tier:not(:last-of-type) {
    margin-bottom: 10px;
}

.QuotePartCollapsable__tier-options {
    display: grid;
    gap: 10px;
    box-sizing: border-box;
    margin-top: 10px;
}

.QuotePartCollapsable__root-three-columns .QuotePartCollapsable__tier-options {
    grid-template-columns: repeat(3, 1fr);
}

.QuotePartCollapsable__root-two-columns .QuotePartCollapsable__tier-options {
    grid-template-columns: repeat(2, 1fr);
}

.QuotePartCollapsable__root-one-column .QuotePartCollapsable__tier-options {
    grid-template-columns: 1fr;
}

.QuotePartCollapsable__tier-options-expand {
    grid-template-columns: 1fr !important;
}

.QuotePartCollapsable__tier-option {
    min-width: 250px;
    border-radius: 3px;
    border: var(--border-main);
    overflow: hidden;
    user-select: none;
    background-color: var(--tones-white);
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
}

.QuotePartCollapsable__tier-option-expand-multi-colour {
    min-width: 100%;
}

.QuotePartCollapsable__tier-option-selected {
    cursor: default;
    background-color: var(--blues-light);
    border: var(--border-blue);
}

.QuotePartCollapsable__tier-option-multi-colour-selected {
    cursor: pointer;
}

.QuotePartCollapsable__tier-option-border-blocker {
    background-color: var(--tones-white);
    border: var(--border-main);
}

.QuotePartCollapsable__tier-option-multi-colour-select-open {
    background-color: var(--tones-white);
}

.QuotePartCollapsable__tier-option-header {
    color: var(--tones-white);
    background-color: var(--tones-dark-grey);
    width: 100%;
    padding: 0 15px !important;
    height: 22px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.QuotePartCollapsable__tier-option-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 15px;
    overflow: hidden;
    height: calc(100% - 30px);
}

.QuotePartCollapsable__tier-option-content-with-header {
    height: calc(100% - 52px);
}

.QuotePartCollapsable__tier-option-content-right {
    width: 100%;
}

.QuotePartCollapsable__tier-option-content > div:not(:first-of-type) {
    margin-left: 15px;
}

.QuotePartCollapsable__tier-option-colour {
    width: 20px;
    height: auto;
    align-self: stretch;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.QuotePartCollapsable__tier-option-colour-border {
    border: var(--border-main);
}

.QuotePartCollapsable__tier-option-multi-colour {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    margin-bottom: 1px;
    border-radius: 2px;
}

.QuotePartCollapsable__tier-option-multi-colour-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.QuotePartCollapsable__tier-option-multi-colour-plus-icon {
    font-size: 14px !important;
    color: var(--tones-dark-grey);
}

.QuotePartCollapsable__tier-option-multi-colour:last-of-type {
    margin-bottom: 0;
}

.QuotePartCollapsable__tier-option-selected-icon-wrap {
    align-self: center;
}

.QuotePartCollapsable__tier-option-selected-icon {
    font-size: 15px !important;
    color: var(--blues-main);
}

.QuotePartCollapsable__tier-option-tags {
    margin-top: 8px;
    width: 100%;
}

.QuotePartCollapsable__tier-option-tags > div {
    background-color: var(--tones-light-grey);
    display: inline-block;
    box-sizing: border-box;
    border: var(--border-main);
    border-color: var(--tones-dark-grey);
    color: var(--tones-dark-grey);
    border-radius: 3px;
    padding: 2px 4px;
    margin-right: 4px;
    margin-bottom: 4px;
}

.QuotePartCollapsable__tier-option-multi-colour-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: left;
    grid-gap: 8px;
    margin-top: 8px;
}

.QuotePartCollapsable__tier-option-multi-colour-wrap-expand {
    grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
}

.QuotePartCollapsable__tier-option-multi-colour-wrap > div {
    height: 24px;
    box-sizing: border-box;
    background-color: var(--tones-white);
    padding: 1px;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.QuotePartCollapsable__tier-option-multi-colour-wrap > div > div {
    border-radius: 2px;
    height: 100%;
    padding: 2px;
    background-color: var(--tones-white);
    width: 100%;
    box-sizing: border-box;
}

.QuotePartCollapsable__tier-option-multi-colour-wrap > div > div > div {
    border-radius: 2px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.QuotePartCollapsable__tier-option-edit-multi-colour {
    bottom: 3px;
    right: 6px;
    position: absolute;
    color: var(--blues-main);
    cursor: pointer;
    transition: filter 0.2s;
}

.QuotePartCollapsable__tier-option-content-right-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.QuotePartCollapsable__tier-option-content-right-warning-icon {
    color: var(--warning-yellow);
    font-size: 16px !important;
    margin-left: 4px;
}

.QuotePartCollapsable__tier-option-no-click {
    cursor: default;
}

.QuotePartCollapsable__tier-option-from-value {
    color: var(--tones-dark-grey);
}

.QuotePartCollapsable__tier-option-colours-preview-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 5px;
    margin-top: 5px;
}

.QuotePartCollapsable__tier-option-colours-preview, .QuotePartCollapsable__tier-option-colours-preview-etc {
    height: 100%;
    width: 28px;
    margin-right: 3px;
    border-radius: 8px;
    box-sizing: border-box;
}

.QuotePartCollapsable__tier-option-colours-preview:last-of-type {
    margin-right: 0;
}

.QuotePartCollapsable__tier-option-colours-preview-etc {
    border-radius: 0;
    display: flex;
    width: 28px;
    margin-right: 0;
    margin-left: 3px;
    align-items: center;
    justify-content: space-between;
}

.QuotePartCollapsable__tier-option-expand-multi-colour-close {
    top: 0;
    right: 0;
    padding: 5px 6px;
    font-size: 15px;
    position: absolute;
    color: var(--blues-main);
    cursor: pointer;
    transition: filter 0.2s;
}

.QuotePartCollapsable__tier-option-colours-preview-etc > div {
    height: 100%;
    width: 5px;
    border-radius: 50%;
    background-color: var(--tones-dark-grey);
}

@media (hover: hover) {
    .QuotePartCollapsable__tier-option:not(.QuotePartCollapsable__tier-option-multi-colour-select-open):not(.QuotePartCollapsable__tier-option-no-click):hover {
        background-color: var(--blues-light);
        border: var(--border-blue);
    }

    .QuotePartCollapsable__tier-option-multi-colour-wrap > div:hover {
        background-color: var(--blues-main);
    }

    .QuotePartCollapsable__tier-option-edit-multi-colour:hover, .QuotePartCollapsable__tier-option-expand-multi-colour-close:hover, .QuotePartCollapsable__tier-option-multi-colour-selected:hover .QuotePartCollapsable__tier-option-expand-multi-colour-close {
        filter: var(--filter-brightness);
    }
}