.UploadingFile {
    position: relative;
    height: 30px;
    width: 100%;
    border-bottom: var(--border-main);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
    overflow: hidden;
}

.UploadingFile > p {
    flex: 2;
    white-space: nowrap;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    transition: color 0.3s;
}

.ProgressEndWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    height: 100%;
    min-width: 230px;
}

.ProgressWrap {
    flex: 1;
    overflow: hidden;
    background-color: #F2F2F2;
    height: 20%;
    border-radius: 7px;
    margin-right: 30px;
    z-index: 1;
}

.Progress {
    width: 100%;
    height: 100%;
    background-color: var(--tones-grey);
    border-radius: 7px;
    transition: transform 0.3s;
    transform: translateX(-100%);
}

.ProgressEndWrap > p {
    color: var(--tones-dark-grey);
    margin-right: 5px;
}

.ProgressEndWrap > p:last-of-type {
    margin-right: 0;
}

.ProgressStatus {
    white-space: nowrap;
}

.MarginRightMessage {
    margin-right: 20px !important;
}

.RemovePartWrap {
    height: 100%;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.RemovePart {
    font-size: 15px !important;
    color: var(--tones-dark-grey);
    transition: color 0.3s;
}

.UploadingFileLoading {
    background-color: var(--tones-off-white);
}

.UploadingFileLoaded {
    background-color: var(--success-light);
}

.UploadingFileMessage {
    background-color: var(--warning-light);
}

.ProgressLoaded {
    background-color: var(--success-main);
}

.ProgressMessage {
    background-color: var(--warning-main);
}

.UploadingFileHovering {
    background-color: var(--blues-light);
}

.ProgressName {
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    text-overflow: ellipsis;
}

.ProgressNameMessage {
    color: var(--warning-main);
}

.ProgressNameHovering {
    color: var(--blues-main);
}

.UploadingSheenWrap {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    animation: sheen 2s ease-in-out infinite;
}

.UploadingSheen {
    position: relative;
    top: -50%;
    left: calc(0% - 30px);
    transform:  rotate(30deg);
    width: 5px;
    background-color: rgba(0, 95, 254, 0.05);
    height: 200%;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .RemovePartWrap:hover > .RemovePart {
        color: var(--blues-main);
    }
}