#ConfirmationHiding {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ConfirmationHiding > div {
    background-image:linear-gradient(30deg, #005ffe 0%, #2258fb 8.33%, #3352f6 16.67%, #3e4bf1 25%, #4744eb 33.33%, #4e3ee5 41.67%, #5437df 50%, #5831d6 58.33%, #5a31c5 66.67%, #5b31b6 75%, #5a30a8 83.33%, #562e90 100%);
    border-radius: 3px;
    box-sizing: border-box;
    padding: 20px;
    height: 350px;
    width: 450px;
}

#ConfirmationHiding > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

#ConfirmationHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#ConfirmationHeaderTop {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

#ConfirmationHeaderTop > h2, #ConfirmationHiding > div > div > h2 {
    color: var(--tones-white);
}

#ConfirmationHeaderTop > p, #ConfirmationHiding > div > div > p {
    color: var(--tones-white);
    text-align: center;
    margin-bottom: 10px;
}

#ConfirmationHeaderTop > p:last-of-type, #ConfirmationHiding > div > div > p:last-of-type {
    margin-bottom: 0;
}

#ConfirmationHeaderBottom {
    margin-top: 23px;
    margin-bottom: 23px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
}

#ConfirmationHeaderBottom > div {
    width: 15%;
    overflow: hidden;
    white-space: nowrap;
    border-right: 2px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-right: 50px;
}

#ConfirmationHeaderBottom > div:last-of-type {
    border-right: none;
}

#ConfirmationHeaderBottom > div > div > p {
    color: var(--tones-white);
}

#ConfirmationHeaderBottom > div > div > p:first-of-type {
    margin-bottom: 5px;
}

#ConfirmationMain {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 65px;
}

#ConfirmationMainLeft {
    margin-right: 40px;
    width: 66%;
}

#ConfirmationMainRight {
    width: 33%;
}

#ConfirmationEstimatedDeliveryWrap {
    border-radius: 3px;
    border: var(--border-main);
    height: 80px;
    width: 100%;
    display: flex;
    background-color: var(--tones-white);
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    overflow: hidden;
}

#ConfirmationEstimatedDeliveryWrap > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#ConfirmationEstimatedDeliveryIcon {
    font-size: 40px !important;
    color: var(--tones-dark-grey);
    margin-left: 20px;
    opacity: 0;
    transition: opacity 0.4s;
}

.AnimateLorry {
    animation: driveIn 3s ease-in-out 1;
}

#EstimatedLeadTime {
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.4s, transform 0.6s;
}

#ConfirmationEstimatedDeliveryWrap > div > div {
    margin-left: 20px;
}

#ConfirmationEstimatedDeliveryWrap > div > div > p:first-of-type {
    color: var(--tones-dark-grey);
}

#ConfirmationEstimatedDeliveryWrap > a, #FetchInvoice {
    text-decoration: underline;
    color: var(--tones-dark-grey);
    transition: color 0.2s;
    margin-right: 20px;
    user-select: none;
    cursor: pointer;
}

#FetchInvoiceWrap {
    position: relative;
}

#FetchInvoiceCircular {
    position: absolute;
    left: -30px;
}

.ConfirmationTitle {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-left: 3px solid var(--blues-main);
}

.ConfirmationTitle > h3 {
    margin-left: 10px;
}

#ConfirmationOrderSummary {
    width: 100%;
    border-radius: 3px;
    border: var(--border-main);
    box-sizing: border-box;
    margin-bottom: 20px;
    background-color: var(--tones-white);
}

#ConfirmationOrderSummaryHeader {
    display: flex;
    white-space: nowrap;
    align-items: center;
    background-color: var(--tones-light-grey);
    padding: 15px;
    border-bottom: var(--border-main);
}

#ConfirmationOrderSummaryHeader > p {
    margin-right: 15px;
    flex: 3;
}

#ConfirmationOrderSummaryHeader > p:first-of-type, #ConfirmationOrderSummaryHeader > p:last-of-type {
    flex: 1;
}

#ConfirmationOrderSummaryHeader > p:first-of-type {
    margin-right: 15px;
}

#ConfirmationOrderSummaryHeader > p:last-of-type {
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
}

.ConfirmationPart {
    height: 80px;
    width: 100%;
    border-bottom: var(--border-main);
    display: flex;
    align-items: center;
}

.ConfirmationPart:last-of-type {
    border-bottom: none;
}

.ConfirmationPart > div {
    height: 100%;
    flex: 3;
    margin-right: 15px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.ConfirmationPart > div:first-of-type, .ConfirmationPart > div:last-of-type {
    flex: 1;
}

.ConfirmationPart > div:first-of-type {
    height: 60px;
    width: 60px;
    margin-left: 15px;
}

.ConfirmationPartThumbnailWrap {
    position: relative;
    height: 60px;
    width: 60px;
}

.ConfirmationPartThumbnail {
    height: 60px;
    width: 60px;
    opacity: 0;
    transition: opacity 0.2s;
}

.ConfirmationPartThumbnailLoadingWrap {
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    height: 60px;
    width: 60px;
    z-index: 2;
    background-color: var(--blues-trans);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.2s;
}

.ConfirmationPart > div > p, .ColourRight > p {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.ColourRight {
    width: 100%;
}

.ColourRight > p {
    width: calc(100% - 28px) !important;
}

.ConfirmationPart > div > p:first-of-type, .ColourRight > p:first-of-type {
    color: var(--tones-black);
    margin-bottom: 3px;
}

.ConfirmationPart > div > p:last-of-type, .ColourRight > p:last-of-type{
    color: var(--tones-dark-grey);
}

.ConfirmationPart > div:not(.ColourLeft):first-of-type {
    margin-right: 14px;
}

.ConfirmationPart > div:not(.ColourLeft):last-of-type {
    margin-right: 14px;
}

.ConfirmationPart > div:not(.ColourLeft):last-of-type {
    display: flex;
    align-items: flex-end;
}

.ConfirmationPart > div:last-of-type > p {
    margin-bottom: 0;
    color: var(--blues-main);
    text-align: right;
}

.Colour {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.ColourLeft {
    background-color: #3a3ff9;
    height: 60%;
    border-radius: 3px;
    width: 18px;
    min-width: 18px;
    box-sizing: border-box;
    margin-right: 10px;
}

#BillingWrap > .ConfirmationTitle, #ShippingWrap > .ConfirmationTitle {
    margin-bottom: 20px;
}

#BillingWrap > div, #ShippingWrap > div {
    margin-bottom: 20px;
}

#BillingWrap > div > p:first-of-type, #ShippingWrap > div > p:first-of-type {
    color: var(--tones-dark-grey);
    margin-bottom: 3px;
}

#BillingWrap > div > p:last-of-type, #ShippingWrap > div > p:last-of-type {
    color: var(--tones-black);
}

#BillingWrap {
    padding-top: 30px;
    border-top: var(--border-thick);
}

#ShippingWrap > div:last-of-type {
    margin-bottom: 30px;
}

#ConfirmationPriceBreakdownWrap {
    width: 100%;
    margin-bottom: 50px;
}

#ConfirmationPriceBreakdownWrap > div {
    width: 100%;
    border-bottom: 2px solid #999999;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#ConfirmationPriceBreakdownWrap > div:first-of-type {
    border-bottom: 2px dotted #999999;
}

#ConfirmationPriceBreakdownWrap > div:last-of-type {
    border-bottom: none;
}

#ConfirmationPriceBreakdownWrap > div:last-of-type > div {
    flex: 1;
    padding-bottom: 20px;
    border-bottom: 2px solid #999999;
}

#ConfirmationPriceBreakdownWrap > div:last-of-type > div:last-of-type {
    box-shadow: inset 0 -4px #333333;
    border-bottom: none;
    display: flex;
    justify-content: flex-end;
}

.TotalPrice > p {
    position: absolute;
}

#ConfirmationPriceBreakdownWrap > div > p, #ConfirmationPriceBreakdownWrap > div:last-of-type > div > p, .ConfirmationBreakdownElemColourSetup p {
    color: var(--tones-dark-grey);
}

#ConfirmationPriceBreakdownWrap > div > p:last-of-type {
    color: var(--tones-black);
}

#ConfirmationPriceBreakdownWrap > div:last-of-type > div:last-of-type > p {
    color: var(--blues-main);
}

#ConfirmationMainLeft > p {
    color: var(--tones-black);
}

#ConfirmationMainLeft > p > a {
    color: var(--blues-main);
    text-decoration: none;
}

.ConfirmationBreakdownElemColourSetup {
    justify-content: flex-start;
}

.ConfirmationBreakdownElemColourSetup > p:last-of-type {
    text-align: end;
    flex: 1;
}

.ConfirmationBreakdownElemColourPreview {
    width: 40px;
    min-width: 40px;
    height: 15px;
    border-radius: 2px;
    box-sizing: border-box;
    margin-right: 7px;
}

.ConfirmationBreakdownElemColourLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ConfirmationBreakdownElemColourLabel > p {
    margin-right: 3px;
}

#ConfirmationMinimumOrderCostHelpIcon {
    vertical-align: middle;
    font-size: 18px;
    color: var(--tones-dark-grey);
    transition: color 0.2s;
    cursor: pointer;
    margin-left: 5px;
}

.ConfirmationSummarySection {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-radius: 3px;
    border: var(--border-main);
    background-color: var(--tones-white);
    margin-top: 10px;
    color: var(--tones-dark-grey);
}

.ConfirmationSummarySection:last-of-type {
    margin-top: 26px;
}

.ConfirmationSummarySection:first-of-type > div:first-of-type > h3 {
    color: var(--tones-black);
}

.ConfirmationSummarySection > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--border-main);
    padding: 0 20px;
    height: 58px;
    box-sizing: border-box;
    white-space: nowrap;
}

.ConfirmationSummarySection > div:last-of-type {
    border-bottom: none;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #ConfirmationEstimatedDeliveryWrap > a:hover, #FetchInvoice:hover {
        color: var(--blues-main);
    }

    #ConfirmationMinimumOrderCostHelpIcon:hover {
        color: var(--blues-main);
    }

    #WantToRegisterModalClose:hover {
        filter: var(--filter-brightness);
    }

    #WantToRegisterModal > form > button:hover {
        background-color: var(--blues-dark);
    }
}