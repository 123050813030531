@keyframes dash {
    from {
        stroke-dashoffset: 6000;
    }
    to {
        stroke-dashoffset: -6000;
    }
}

.loadingSVGPath {
    font-size: 60px;
    stroke-dasharray: 1000, 5000;
    stroke-dashoffset: 50%;
    animation: dash 5s linear infinite;
}