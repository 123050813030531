#BulkPricesModalWrap {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
}

#BulkPricesModalWrap > div {
    position: relative;
    width: 490px;
    /* max-height: 450px; */
    border-radius: 4px 5px 4px 4px;
    background-color: var(--tones-white);
    box-shadow: var(--box-shadow-main);
}

#BulkPricesModalTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--border-main);
}

#BulkPricesModalTop > p {
    margin-left: 25px;
}

#BulkPricesModalCloseButton {
    border: none;
    outline: none;
    height: 60px;
    z-index: 2;
    width: 70px;
    border-top-right-radius: 4px;
    background-color: var(--tones-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

#BulkPricesModalCloseButtonIcon {
    font-size: 22px;
    color: var(--tones-dark-grey);
    transition: color 0.2s;
}

#BulkPricesModalBottom {
    position: relative;
    width: 100%;
    max-height: 370px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: auto;
}

.BulkPricesModalLineHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border-bottom: var(--border-dotted);
}

.BulkPricesModalLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border-bottom: var(--border-dotted);
    transition: background-color 0.3s;
}

.BulkPricesModalLine:last-of-type {
    border-bottom: none;
}

/* .BulkPricesModalLine:first-of-type {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--tones-white);
} */

/* .BulkPricesModalLine:not(:first-of-type) {
    cursor: pointer;
} */

.BulkPricesModalLine > p, .BulkPricesModalLineHeader > p {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #BulkPricesModalCloseButton:hover {
        background-color: var(--blues-main);
    }

    #BulkPricesModalCloseButton:hover > #BulkPricesModalCloseButtonIcon {
        color: var(--tones-white);
    }

    .BulkPricesModalLine:hover {
        background-color: var(--blues-light);
    }
}