#UserDeleteAreYouSureWrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 51;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 0, 0, 0.3);
    animation: fadeIn 0.5s linear 1, warningBackground 1.5s ease-in-out infinite;
}

#UserDeleteAreYouSureModal {
    position: relative;
    width: 300px;
    background-color: var(--tones-white);
    border-radius: 5px;
    border: var(--border-main);
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
}

#UserDeleteAreYouSureModal > p {
    text-align: center;
}

#UserDeleteAreYouSureModalClose {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    color: var(--tones-black);
}

#UserDeleteAreYouSureModal > p {
    margin: 20px 0;
}

#UserDeleteAreYouSureModalButtonWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #UserDeleteAreYouSureModalButtonWrap > button:hover {
        filter: var(--filter-brightness);
    }

    #DeleteUserButton:hover {
        filter: var(--filter-brightness);
    }
}