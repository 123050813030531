@media screen and (max-width: 1024px) {
    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote */

    .QuoteToolbarElem:first-of-type {
        flex: 1;
    }

    .QuoteToolbarElem:not(:first-of-type):not(:last-of-type) {
        padding-right: 20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote part */

    .QuotePart > div:not(.TechnologyFinishWrap), .SkeletonThumbnail, .SkeletonInfoWrap, .SkeletonsQuantityPriceWrap {
        flex: unset !important;
    }

    .SkeletonsQuantityPriceWrap {
        width: unset;
        min-width: unset;
        justify-content: unset;
    }

    .SkeletonSetButtonWrap {
        padding: 15px;
        align-items: flex-end;
        justify-content: space-between;
    }

    .SkeletonSetButtonWrap > span:not(:last-of-type) {
        margin-bottom: 5px;
        width: 100% !important;
    }

    .QuantityPriceWrap {
        min-width: unset;
        width: unset;
    }

    .PartPriceWrap {
        margin-left: 0;
    }

    .TechnologyFinishWrap {
        flex-direction: column;
        justify-content: space-between;
        height: 80%;
    }

    .TechnologyFinishWrapRoot {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 90px;
        width: 92%;
        min-width: 250px;
    }

    .TechnologyFinishInfo {
        width: 100%;
        box-sizing: border-box;
        padding: 4px 10px;
    }

    .TechnologyFinishInfo > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .TechnologyFinishInfo > div > p:first-of-type {
        min-width: 40%;
        width: 40%;
        text-align: left;
    }

    .TechnologyFinishInfo > div > p:nth-of-type(2), .TechnologyFinishInfo > div > div {
        flex: 1;
        max-width: 60%;
    }

    .TechnologyFinishEdit {
        width: 100%;
        height: auto;
        padding: 3px 0;
    }

    .FlexedQuantityInputWrap {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 92%;
        min-width: 250px;
    }

    .FlexedQuantityInputWrap > div {
        width: 200px;
    }

    .QuotePartLeft {
        flex: 1;
    }

    .QuotePartSplit .QuantityPriceWrap {
        width: 100% !important;
    }

    .PartInfoWrap > h3 {
        max-width: 150px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quantity input */

    .QuantityInputFlexed {
        width: 100% !important;
        align-items: center !important;
        justify-content: space-between !important;
        flex-direction: row !important;
        height: unset !important;
        flex: unset !important;
    }

    .QuantityInputFlexed > p {
        margin: 0;
    }

    .QuantityInputFlexed > .Quantity {
        max-width: 125px;
        height: 35px;
        min-height: 35px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* checkout */

    #CheckoutContentWrap {
        min-height: unset;
    }

    #CheckoutContent {
        display: initial;
        min-height: unset;
    }

    .CheckoutHalf {
        min-height: unset;
    }

    .CheckoutHalf:first-child {
        background-color: var(--tones-off-white);
    }

    .CheckoutHalf > div {
        margin: 0 auto;
    }

    .CheckoutHalf:first-child > div {
        margin: 70px auto 0 auto;
    }

    .CheckoutSummarySection:last-child, #CheckoutPaymentOptions {
        margin-bottom: 30px;
    }

    .CheckoutSkeletonForm {
        margin-top: 25px;
    }

    .CheckoutUpgradeWrap {
        margin-bottom: 30px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* confirmation */

    #ConfirmationHeaderBottom > div {
        width: 135px;
        margin-right: 40px;
    }

    #ConfirmationHeaderBottom > div > div > p:last-child {
        font-size: 14px !important;
    }

    #ConfirmationMain {
        flex-direction: column;
        margin-bottom: 40px;
    }

    #ConfirmationMainRight {
        width: 100%;
    }

    #UserInformationWrap {
        width: 100%;
        margin-bottom: 20px;
    }

    #UserInformationWrap > div, .UserAddressSkeleton {
        width: calc(50% - 20px);
        display: inline-block !important;
        margin-top: 0 !important;
    }

    #UserInformationWrap > div:not(:last-child), .UserAddressSkeleton:not(:last-child) {
        margin-right: 39px;
    }

    #ConfirmationMainLeft {
        width: 100%;
        margin-right: 0;
    }

    #ConfirmationPriceBreakdownWrap {
        margin-bottom: 20px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user */

    .UserSectionTitle, .UserInfoElem > p, .UserInfoElem > h4, .UserSectionButtonWrap > button, #DeleteUserButton, #UserDeleteAreYouSureModalButtonWrap > button {
        font-size: 14px !important;
    }

    .UserSectionTitle {
        margin-left: 15px;
    }

    .UserSectionContent {
        padding: 10px 15px;
    }

    .UserInfoElem:first-of-type {
        margin-top: 15px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* user table */
    .UserTableSearch {
        font-size: 14px;
        padding: 7px 15px;
    }

    #UserTableMainHeadersWrap, .UserTableRow {
        padding: 0 10px;
    }

    .UserTableRow {
        height: 58px;
    }

    .UserTableMainHeader > h3 {
        font-size: 14px !important;
    }

    .UserTableRowLink > p, .UserTableRowCell > p:first-of-type, .UserTableRowStatusCell > p:first-of-type {
        font-size: 12px;
    }

    .UserTableRowCell > p:nth-child(2), .UserTableRowStatusCell > p:nth-child(2), .UserTableRowLink > p:nth-child(2) {
        font-size: 10px;
    }

    .UserTableRowActions {
        padding: 10px;
        min-width: 100px;
    }

    /* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
    /* quote part collapsable */
    .QuotePartCollapsable__root {
        padding: 10px;
    }

    .QuotePartCollapsable__tier-option-header {
        height: 20px;
    }

    .QuotePartCollapsable__tier-option-content-right-label {
        font-size: 14px !important;
    }

    .QuotePartCollapsable__tier-option-content {
        margin: 5px 15px;
        height: calc(100% - 10px);
    }

    .QuotePartCollapsable__tier-option-content-with-header {
        height: calc(100% - 30px);
    }

    .QuotePartCollapsable__tier-option-tags {
        margin-top: 4px;
    }
}