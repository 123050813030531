#Checkout {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

#CheckoutContentWrap {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom: var(--border-main);
}

#CheckoutContent {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CheckoutHalf {
    flex: 1;
    min-height: 100%;
    background-color: var(--tones-off-white);
}

.CheckoutHalf:first-child {
    background-color: var(--blues-mid);
}

.CheckoutHalf > div {
    position: relative;
    width: 526px;
    box-sizing: border-box;
    margin: 122px auto 0 auto;
}

.CheckoutSummarySection {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-radius: 3px;
    border: var(--border-main);
    background-color: var(--tones-white);
    margin-top: 25px;
    color: var(--tones-dark-grey);
}

.CheckoutSummarySection:last-child {
    margin-bottom: 108px;
}

#CheckoutSummaryBottom {
    padding: 20px;
}

#CheckoutSummaryBottom > button, #CheckoutSubmitSSLButton, .CheckoutUpgradeButton {
    border-radius: 3px;
    background-color: var(--blues-dark);
    color: var(--tones-white);
    border: none;
    outline: none;
    width: 100%;
    user-select: none;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.2s, filter 0.2s;
    margin-top: 15px;
}

.CheckoutUpgradeWrap {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    background-color: var(--tones-white);
    border: var(--border-main);
    border-radius: 3px;
    margin-bottom: 30px;
}

#CheckoutSummaryBottom > button:disabled, #CheckoutSubmitSSLButton:disabled {
    background-color: var(--tones-dark-grey) !important;
    cursor: initial;
}

.CheckoutUpgradeButton {
    background-color: var(--warning-sick-yellow);
    color: var(--tones-black);
    cursor: pointer;
    margin-top: 10px;
}

#CheckoutSummaryBottom > p {
    margin-top: 8px;
}

#CheckoutLinkToQuote {
    position: absolute;
    top: -38px;
    left: 0;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

#CheckoutLinkToQuote > p {
    margin-left: 5px;
}

.CheckoutSummarySection:first-child {
    margin-top: 0;
}

.CheckoutSummaryElem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--border-main);
    padding: 0 20px;
    height: 58px;
    box-sizing: border-box;
    white-space: nowrap;
}

.CheckoutSummaryElem:last-child {
    border-bottom: none;
}

.CheckoutSummaryElemColour {
    justify-content: flex-start;
}

.CheckoutSummaryElemColour > p:last-of-type {
    flex: 1;
    text-align: right;
}

.CheckoutSummaryElemColourPreview {
    width: 40px;
    min-width: 40px;
    height: 15px;
    border-radius: 2px;
    box-sizing: border-box;
    margin-right: 7px;
}

.CheckoutSummaryElemColourLabel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.CheckoutSummaryElemColourLabel > p {
    margin-right: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
}

#CheckoutPaymentOptions {
    border: var(--border-main);
    box-sizing: border-box;
    background-color: var(--tones-white);
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 108px;
}

#CheckoutOptionSelect {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CheckoutOptionTab {
    user-select: none;
    flex: 1;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-right: var(--border-main);
    border-bottom: var(--border-main);
    background-color: var(--tones-light-grey);
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
}

.CheckoutOptionSelected {
    background-color: var(--tones-white);
    border-left: none;
    border-right: none;
    cursor: default;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.CheckoutOptionSelected + .CheckoutOptionTab {
    border-bottom-left-radius: 3px;
    border-left: var(--border-main);
}

.CheckoutOptionTab:last-child {
    border-right: none;
}

.CheckoutOptionDisabled {
    pointer-events: none !important;
    cursor: initial !important;
}

#CheckoutPOInputWrap {
    padding: 40px;
    box-sizing: border-box;
    color: var(--tones-dark-grey);
}

#CheckoutPOInputWrap > p:last-of-type {
    margin-bottom: 15px;
}

#CheckoutForm {
    width: 100%;
    align-self: center;
    box-sizing: border-box;
    border-top: var(--border-main);
}

.PaddedFormElem {
    padding: 30px 40px 0 40px;
    border-bottom: var(--border-main)
}

.PaddedFormElem:last-child {
    border-bottom: none;
    padding-bottom: 20px;
}

#CheckoutTerms {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

#CheckoutTerms > p > a {
    text-decoration: none;
}

#CheckoutButton, #POApplyButton {
    width: 300px;
    border-radius: 3px;
    background-color: var(--blues-dark);
    color: var(--tones-white);
    border: none;
    outline: none;
    user-select: none;
    box-sizing: border-box;
    padding: 15px 25px;
    max-height: 50px;
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.2s, filter 0.2s;
}

#CheckoutButton:disabled, #POApplyButton:disabled {
    pointer-events: none;
}

.PaymentLoadingSpinner {
    color: var(--tones-white) !important;
}

#CheckoutForm input {
    outline: none !important;
}

#PaymentElement {
    margin-bottom: 24px;
}

#PaymentMessage {
    margin-top: 5px;
    color: var(--warning-main);
}

#StripeAnchor {
    text-decoration: none;
}

#PoweredBy {
    cursor: pointer;
    margin-top: 20px;
    user-select: none;
    padding: 0 5px;
    height: 22px;
    background-color: var(--tones-light-grey);
    width: 90px;
    border: var(--border-main);
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    opacity: 0.8;
    transition: background-color 0.3s;
}

#PoweredBy > p {
    color: var(--tones-dark-grey);
    font-weight: 500;
    font-size: 9px;
    white-space: nowrap;
    z-index: 1;
}

.QuoteSkeletonLinkBack {
    position: absolute;
    top: -38px;
    left: 0;
}

.CheckoutUpgradeP {
    margin-top: 30px;
    margin-bottom: 5px;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */

#POInputs {
    padding: 0 40px;
}

#PONameInputs {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#UploadPO {
    margin-top: 30px;
    padding: 0 40px;
}

#POUploadButton {
    box-sizing: border-box;
    border: var(--border-main);
    background-color: var(--tones-white);
    color: var(--tones-black);
    width: 100%;
    padding: 15px 0;
    border-radius: 3px;
    margin-top: 15px;
    transition: background-color 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

#POUploadButton[dragactive="true"] {
    background-color: var(--blues-light) !important;
}

#POUploadButton[warning="true"] {
    border: var(--border-warning);
    background-color: var(--warning-light);
}

#pOUploadMessage {
    color: var(--blues-main);
    margin-top: 5px;
}

#pOUploadMessage[warning="true"] {
    color: var(--warning-main) !important;
}

#UploadPurchaseOrderIcon {
    stroke: var(--tones-black);
    margin-right: 10px;
}

.POUploadButtonDisabled {
    pointer-events: none !important;
    background-color: var(--tones-light-grey) !important;
    color: var(--tones-dark-grey) !important;
    cursor: initial !important;
}

#POSubmit {
    margin-top: 30px;
    padding: 0 40px;
    margin-bottom: 20px;
}

#CheckoutSSLModalWrap {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blues-trans);
}

#CheckoutSSLModalWrap > div {
    width: 480px;
    min-width: 480px;
    height: 280px;
    background-color: var(--tones-white);
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    box-shadow: var(--box-shadow-main);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#CheckoutSSLModalWrap > div > p {
    margin-bottom: 20px;
}

#CheckoutSSLModalCloseButton {
    position: absolute;
    border: none;
    outline: none;
    top: 0;
    right: 0;
    height: 50px;
    z-index: 2;
    width: 50px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 4px;
    background-color: var(--tones-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

#CheckoutSSLModalCloseButtonIcon {
    font-size: 22px;
    color: var(--tones-dark-grey);
    transition: color 0.2s;
}

#POInputs > p:nth-child(2), #UploadPO > p:nth-child(2), #POApplyWrap > p:nth-child(3) {
    color: var(--tones-dark-grey);
}

#POApplyWrap {
    padding: 25px 40px;
}

#POApplyButton {
    width: 100%;
    margin-top: 20px;
}

.CheckoutButtonDisabled {
    background-color: var(--tones-dark-grey) !important;
    cursor: initial !important;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    #CheckoutSummaryBottom > button:hover:not(.CheckoutButtonDisabled), #CheckoutSubmitSSLButton:hover:not(.CheckoutButtonDisabled) {
        filter: var(--filter-brightness);
    }

    #CheckoutLinkToQuote:hover {
        filter: var(--filter-brightness);
    }

    .CheckoutOptionTab:not(.CheckoutOptionSelected):hover {
        background-color: var(--blues-light);
    }

    #CheckoutButton:hover:not(.CheckoutButtonDisabled), #POApplyButton:hover:not(.CheckoutButtonDisabled) {
        filter: var(--filter-brightness);
        pointer-events: all !important;
    }

    #PoweredBy:hover {
        background-color: var(--blues-light);
    }

    #POUploadButton:hover {
        background-color: var(--blues-light) !important;
    }

    #CheckoutSSLModalCloseButton:hover {
        background-color: var(--blues-main);
    }

    #CheckoutSSLModalCloseButton:hover > #CheckoutSSLModalCloseButtonIcon {
        color: var(--tones-white);
    }
}