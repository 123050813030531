.InformationDisplay {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.InformationDisplayLoading, .OverviewCarouselLoading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blues-trans);
}

.OverviewCarouselLoading {
    background-color: var(--tones-light-grey);
}

.InformationDisplayHeader {
    width: 100%;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 10px;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: var(--border-main);
}

.InformationDisplayHeader p:first-child {
    margin-bottom: 4px;
}

.InformationDisplayTabs {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.InformationDisplayTabs::after {
    content: "";
    position: absolute;
    bottom: -5px;
    pointer-events: none;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 5px;
    background: linear-gradient(var(--tones-white), transparent);
}

.InformationDisplayTab {
    background-color: var(--tones-light-grey);
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    border-left: 1px solid var(--tones-light-grey);
    border-right: 1px solid var(--tones-light-grey);
    border-bottom: var(--border-main);
    color: var(--tones-dark-grey);
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    overflow: hidden;
    transition: filter 0.3s, color 0.3s, background-color 0.3s, border-color 0.3s;
}

.InformationDisplayTabActive {
    cursor: initial;
    background-color: var(--tones-white);
    color: var(--tones-black);
    border-color: var(--tones-white) !important;
}

.InformationDisplayTabBorderLeft {
    border-left-color: var(--tones-grey);
}

.InformationDisplayTabBorderRight {
    border-right-color: var(--tones-grey);
    border-bottom-right-radius: 3px;
}

.InformationDisplayTabBorderBottomLeft {
    border-bottom-left-radius: 3px;
}

.InformationDisplayContentWrap {
    position: absolute;
    top: calc(65px + 36px);
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.InformationDisplayContent {
    width: 100%;
    box-sizing: border-box;
}

.InformationDisplaySomethingWentWrong {
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InformationDisplayContent a {
    color: var(--blues-main);
}

.OverviewInfoCarouselWrap {
    height: 220px;
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 20px);
    margin: 10px 10px 0 10px;
    border-radius: 3px;
    overflow: hidden;
}

.OverviewInfoValue {
    padding: 10px;
    border-bottom: var(--border-main);
}

.DesignGuideInfoValue {
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: var(--border-main);
    display: flex;
    min-height: 90px;
    cursor: pointer;
    align-items: stretch;
    justify-content: space-between;
}

/* left side will have the design guide image of which the max width will by 110px */
.DesignGuideInfoValueLeft {
    width: 100px;
    min-height: calc(70px - 20px);
    overflow: hidden;
    display: flex;
    align-items: stretch;
}

.DesignGuideImageWrap {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    /* center background image, scale to cover */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    z-index: 1;
    border-radius: 3px;
}

.DesignGuideImageWrap img {
    width: 100%;
    object-fit: cover;
}

.DesignGuideInfoValueCollapsed .DesignGuideInfoValueLeft {
    min-width: 120px;
    width: 120px;
    max-width: 120px;
}

.DesignGuideInfoValueCollapsed .DesignGuideImageWrap {
    /*aspect-ratio: 1 / 1;*/
    min-height: 120px;
}

/* right side will contain title, values and text content */
.DesignGuideInfoValueRight {
    margin-left: 10px;
    flex: 1;
    min-height: calc(70px - 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    white-space: nowrap;
}

.DesignGuideInfoValueRight > p:not(.DesignGuideInfoValueContent) {
    margin-top: 4px;
    margin-bottom: 4px;
}

/* values wrap should fill the parent elem, and flex its children, it should flex 2 elems as a column and then wrap to the next line */
.DesignGuideInfoValueValuesWrap {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
}

.DesignGuideInfoValueValuesWrap > div {
    display: flex;
    margin-right: 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    color: rgba(253, 85, 45, 1);
}

/* values dot */
.DesignGuideInfoValueValuesWrap > div > div {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: rgba(253, 85, 45, 1);
    margin-right: 4px;
}

.DesignGuideInfoValueContent {
    margin-top: 4px;
    white-space: pre-wrap;
}

.PropertiesInfoValue {
    position: relative;
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: var(--border-main);
}

.PropertiesInfoValue > p:nth-child(2) {
    margin-top: 4px;
}

.FloatingCollapsableButton {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    box-sizing: border-box;
    width: 40px;
    right: 0;
}

.FloatingCollapseButtonIcon {
    color: var(--tones-dark-grey);
    transition: filter 0.3s;
    font-size: 20px !important;
}

.FloatingCollapseButtonIconClose {
    font-size: 17px !important;
}

.InformationDisplayEMSP {
    margin-right: 15px;
}

.InformationDisplayBRMB {
    margin-bottom: 5px;
}

.InformationDisplayBOLD {
    font-weight: bold;
}

.InformationDisplayITALIC {
    font-style: italic;
}

.InformationDisplayTableWrap {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.InformationDisplayTable {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    font-size: 12px;
    border-collapse: collapse;
    white-space: nowrap;
}

.InformationDisplayTable thead {
    font-weight: bold;
    color: var(--tones-dark-grey);
    border-bottom: 2px solid black;
}

.InformationDisplayTable th, .InformationDisplayTable td {
    padding: 5px 0;
    border-bottom: 1px solid var(--tones-light-grey);
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* first th & td in each row slightly bigger */
.InformationDisplayTable th:first-child, .InformationDisplayTable td:first-child {
    max-width: 150px;
    padding-right: 4px;
}

/* last th & td in each row align right */
.InformationDisplayTable th:last-child, .InformationDisplayTable td:last-child {
    text-align: right;
    padding-right: 0;
}

/* last table row no border bottom */
.InformationDisplayTable tr:last-child td {
    border-bottom: none;
}

/* hover media rule to prevent hover events happening on touchscreen devices */
@media (hover: hover) {
    .InformationDisplayTab:not(.InformationDisplayTabActive):hover {
        filter: var(--filter-brightness);
    }

    .FloatingCollapsableButton:hover .FloatingCollapseButtonIcon {
        filter: var(--filter-brightness);
    }
}