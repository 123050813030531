.QuantityInput {
    flex: 0.7;
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.QuantityInput > p {
    margin-bottom: 8px;
}

.Quantity {
    border: var(--border-main);
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    width: 40%;
    min-width: 100px;
    height: 45%;
    align-items: center;
    justify-content: space-between;
}

.Quantity > input {
    outline: none;
    border: none;
    height: 100%;
    width: 0;
    flex: 5;
    text-align: center;
}

.QuantityArrowsWrap {
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.QuantityArrowsWrap > div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex: 1;
    width: 100%;
    background-color: var(--tones-white);
    border-left: var(--border-main);
    cursor: pointer;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.QuantityArrowsWrap > div:first-of-type {
    border-bottom: var(--border-main);
}

.QuantityButtonDisabled {
    pointer-events: none;
    filter: brightness(90%);
}

.QuantityBulkButtonDisabled {
    pointer-events: none;
    color: var(--tones-dark-grey) !important;
}

.QuantityArrow {
    color: var(--tones-dark-grey);
    font-size: 15px !important;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.QuantityInputLocked {
    pointer-events: none;
}

.BulkPrices {
    color: var(--blues-main);
    cursor: pointer;
    user-select: none;
    margin: 0 !important;
    position: relative;
    top: 8px;
    white-space: nowrap;
}

/* // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
/* hover */

@media (hover: hover) {
    .QuantityArrowsWrap > div:hover {
        background-color: var(--blues-main);
        border-left: 1px solid var(--blues-main);
    }

    .QuantityArrowsWrap > div:first-of-type:hover {
        border-bottom: var(--border-blue);
    }

    .QuantityArrowsWrap > div:hover > .QuantityArrow {
        color: var(--tones-white);
    }

    .BulkPrices:hover {
        filter: var(--filter-brightness);
    }
}