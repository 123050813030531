.TextInputWrap > p:first-of-type {
    color: var(--tones-dark-grey);
}

.TextInputWrap > p:nth-child(3) {
    margin-top: 2px;
}

.TextInput {
    background-color: var(--tones-white);
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: var(--border-main);
    border-radius: 3px;
    padding: 10px 15px;
    white-space: nowrap;
    transition: border 0.3s;
    color: var(--tones-dark-grey);
}

.TextInputWarning, .TextInput[indicatewarning = 'true'] {
    border: var(--border-warning) !important;
}

.InputRequired::after {
    content: '*';
    color: var(--blues-main);
    position: relative;
    left: 3px;
}

.TextInput:focus {
    border: var(--border-blue);
}

.InputWrittenWarning {
    color: var(--warning-main);
    margin-bottom: 4px;
}

.TextInputDisabled {
    background-color: var(--tones-light-grey);
}